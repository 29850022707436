import React from 'react'
import PropTypes from 'prop-types'
import AppContext from '../context/AppContext'
import { useContext, useEffect } from 'react'
import Constants from '../utils/Constants'
import ReactMarkdown from 'react-markdown'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import rehypeRaw from 'rehype-raw'
const MainHeadline = ({
  title,
  desciption,
  fullDescription,
  dynamicHeader,
  ongoingBadge,
  bottomLine,
  containerClass,
  extendable,
}) => {
  const { data, setData } = useContext(AppContext)
  const { activeFilters, workout_types, expendedMainHeader } = data

  if (
    dynamicHeader &&
    activeFilters &&
    activeFilters.length > 0 &&
    activeFilters.length === 1 &&
    activeFilters[0].section.id === Constants.WORKOUT_TYPES
  ) {
    title = 'Workout Types'
    workout_types.forEach((workout_type) => {
      if (
        workout_type.id.toString() === activeFilters[0].option.value.toString()
      ) {
        title = workout_type.name
        desciption = workout_type.description
      }
    })
  }
  return (
    <div className={`${containerClass}`}>
      <div className='text-center mt-10 sm:mt-24 sm:pb-2 space-y-1 sm:space-y-3'>
        <div className='text-3xl sm:text-4xl font-extrabold flex flex-row justify-center'>
          <div>{title}</div>
          {ongoingBadge && (
            <div className='relative pt-0.5'>
              <span className='hidden sm:inline-flex sm:absolute border mx-4 mt-4 items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-500'>
                <svg
                  className='-ml-0.5 mr-1.5 h-2 w-2 text-greeny-500'
                  fill='currentColor'
                  viewBox='0 0 8 8'
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Ongoing
              </span>
            </div>
          )}
        </div>
        <div className='text-base font-normal sm:text-lg text-gray-500 max-w-3xl mx-auto whitespace-pre-wrap '>
          {desciption}
          {extendable && fullDescription !== '' && !expendedMainHeader && (
            <span
              onClick={() => {
                setData({ ...data, ...{ expendedMainHeader: true } })
              }}
              className='text-bold cursor-pointer text-gray-900 pl-2 whitespace-nowrap'
            >
              Learn more
              <ChevronDownIcon className='w-5 h-5 pl-1  inline-flex'></ChevronDownIcon>
            </span>
          )}
          {extendable && fullDescription !== '' && expendedMainHeader && (
            <span
              onClick={() => {
                setData({ ...data, ...{ expendedMainHeader: false } })
              }}
              className='text-bold cursor-pointer text-gray-900 pl-2 whitespace-nowrap'
            >
              Collapse
              <ChevronUpIcon className='w-5 h-5 pl-1 -mt-1 inline-flex'></ChevronUpIcon>
            </span>
          )}
        </div>
      </div>
      {extendable && fullDescription !== '' && expendedMainHeader && (
        <div className='text-gray-500 border-t border-gray-200 pt-6 mt-12'>
          <ReactMarkdown
            className='markdownHtmlMainHeadline'
            rehypePlugins={[rehypeRaw]}
          >
            {fullDescription}
          </ReactMarkdown>
        </div>
      )}
      <div className={`${bottomLine ? 'separator' : null} mt-16 mb-6`}></div>
    </div>
  )
}

MainHeadline.defaultProps = {
  title: '',
  desciption: '',
  fullDescription: '',
  dynamicHeader: false,
  ongoingBadge: false,
  bottomLine: false,
  containerClass: 'container',
  extendable: false,
}

MainHeadline.propTypes = {
  title: PropTypes.string,
  desciption: PropTypes.string,
  fullDescription: PropTypes.string,
  dynamicHeader: PropTypes.bool,
  ongoingBadge: PropTypes.bool,
  bottomLine: PropTypes.bool,
  containerClass: PropTypes.string,
  extendable: PropTypes.bool,
}

export default MainHeadline
