import Workout from '../components/Workout'
import PropTypes from 'prop-types'
import WorkoutType from '../components/WorkoutType'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import { useRef, useState } from 'react'
import PanelTopNavigation from '../components/PanelTopNavigation'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

const Carousel = ({
  prefix,
  itemsCount,
  link,
  name,
  data,
  type,
  breakPoints,
  customClassName,
}) => {
  data = data.slice(0, itemsCount)
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [isShowLeft, showLeft] = useState(false)
  const [isShowRight, showRight] = useState(true)

  return (
    <div className='mb-3 sm:mb-5'>
      <div className='container'>
        <PanelTopNavigation link={link} name={`${name}`}></PanelTopNavigation>
      </div>
      <div className='sm:max-w-8xl sm:px-12 xl:px-0 mx-auto'>
        <Swiper
          className={`${customClassName}`}
          watchSlidesVisibility={true}
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
          breakpoints={breakPoints}
          onSlideChangeTransitionStart={(swiper) => {
            // on the first slide
            if (swiper.activeIndex == 0) {
              showLeft(false)
              showRight(true)
            }
            // most right postion
            else if (swiper.activeIndex * 2 == swiper.slides.length) {
              showLeft(true)
              showRight(false)
            }
            // middle positions
            else {
              showLeft(true)
              showRight(true)
            }
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
        >
          <div className='swiper-container'>
            {type === 'workout' &&
              data.map((workout, index) => (
                <SwiperSlide key={`${prefix}-workout-${workout.id}-${index}`}>
                  <Workout
                    prefix={`${prefix}${workout.day ? `/c${workout.day}` : ''}`}
                    workoutId={workout.id}
                    isNewBadge={true}
                    isFavoriteBadge={true}
                  ></Workout>
                </SwiperSlide>
              ))}
            {type === 'classTypes' &&
              data.map((item, index) => (
                <SwiperSlide key={`${prefix}-classtypes-${item.id}-${index}`}>
                  <WorkoutType workout_type={item}></WorkoutType>
                </SwiperSlide>
              ))}
          </div>
          {isShowLeft && (
            <div
              className='group caruselNavLeft hidden sm:block absolute z-50 top-20 left-3'
              ref={prevRef}
            >
              <button className='rounded-full mt-auto bg-white content-center h-8 w-8 border border-white hover:border group-hover:border-gray-900'>
                <ChevronLeftIcon className='inline-block w-5 h-5 p-0.5 -mt-0.5'></ChevronLeftIcon>
              </button>
            </div>
          )}
          {isShowRight && (
            <div
              className='group caruselNavRight hidden sm:block absolute z-50 top-20 right-3 '
              ref={nextRef}
            >
              <button className='rounded-full mt-auto bg-white content-center h-8 w-8 border border-white hover:border group-hover:border-gray-900'>
                <ChevronRightIcon className='inline-block w-5 h-5 p-0.5 -mt-0.5'></ChevronRightIcon>
              </button>
            </div>
          )}
        </Swiper>
      </div>
    </div>
  )
}

Carousel.defaultProps = {
  prefix: '',
  itemsCount: 10,
  link: '',
  name: '',
  data: [],
  type: 'workout',
  breakPoints: {},
  customClassName: 'carousel',
}
Carousel.propTypes = {
  prefix: PropTypes.string,
  itemsCount: PropTypes.number,
  link: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  breakPoints: PropTypes.object,
  customClassName: PropTypes.string,
}

export default Carousel
