import React from 'react'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import Workout from '../components/Workout'
import { Filter } from '../components/Filter'
import Pagination from '../components/Pagination'
import MainHeadline from '../components/MainHeadline'
import NoResults from '../components/NoResults'

const Browse = () => {
  const { data } = useContext(AppContext)
  const { workoutsFilteredPaginated } = data

  return (
    <div className=''>
      <MainHeadline
        title='Browse'
        desciption={`Whatever your body's asking for, and whatever your schedule allows, you'll find the class that is right for you`}
        dynamicHeader={true}
        bottomLine={true}
      ></MainHeadline>
      <Filter></Filter>
      <div className='container'>
        <div className='component align-top mt-1 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-8'>
          {workoutsFilteredPaginated &&
            workoutsFilteredPaginated.length > 0 &&
            workoutsFilteredPaginated.map((workout, index) => (
              <div key={`browse_workout_${workout.id}-${index}`}>
                <Workout workoutId={workout.id} isNewBadge={true}></Workout>
              </div>
            ))}
        </div>
        {workoutsFilteredPaginated &&
          workoutsFilteredPaginated.length === 0 && (
            <NoResults
              text='No results'
              description='Sorry, we could not find any workouts based on your search criteria.'
            ></NoResults>
          )}
        <div>
          <Pagination></Pagination>
        </div>
      </div>
    </div>
  )
}

export default Browse
