const Loader = () => {
  return (
    <div className='h-full flex flex-col '>
      <div className='bg-white shadow-sm'>
        <div className='flex h-16 justify-between items-center'>
          <div className='justify-start items-center '>
            <img
              className='block lg:h-4 xl:h-5 h-3.5 w-auto ml-5'
              src='/withsara-logo.svg'
              alt='Workflow'
            />
          </div>
        </div>
      </div>
      <div className='my-auto'>
        <div className='px-10 sm:px-0 flex flex-col sm:flex-row items-center space-x-0 sm:space-x-5 space-y-3 sm:space-y-0 justify-center absolute w-full'>
          <svg
            className='animate-spin mt-2 sm:-mt-1 mr-2 h-5 w-5 text-gray-300'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            {/* TODO: test on IOS */}
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
          <div className='text-gray-500 text-center sm:text-left'>
            Hold tight - just getting your workouts ready
          </div>
        </div>
      </div>
    </div>
  )
}
export default Loader
