const NotFound = () => {
  return (
    <div className='container'>
      <div className='justify-center text-center text-xl text-gray-400'>
        <div className='mt-10 mb-10'>Page not found</div>
      </div>
    </div>
  )
}

export default NotFound
