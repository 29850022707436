import AppContext from '../context/AppContext'
import { useContext } from 'react'
import MainHeadline from '../components/MainHeadline'
import WorkoutType from '../components/WorkoutType'

const ClassTypesPage = () => {
  const { data } = useContext(AppContext)
  let { workout_types } = data

  return (
    <div className='container'>
      <MainHeadline
        title='Class categories'
        desciption='Find a class type to achieve your goals'
      ></MainHeadline>
      <div className='component align-top mt-9 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-8'>
        {workout_types &&
          workout_types.length > 0 &&
          workout_types.map((workout_type, index) => (
            <div key={`workout_type_${workout_type?.id}-${index}`}>
              <WorkoutType workout_type={workout_type}></WorkoutType>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ClassTypesPage
