import React from 'react'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import WeeklyChallenge from './WeeklyChallenge'
import { useHistory } from 'react-router-dom'
import { getLastUrlPath } from '../utils/Common'

const WeeklyChallenges = () => {
  const { data } = useContext(AppContext)

  const { weekly_challenges } = data
  const readable_url = weekly_challenges[0].readable_url

  // Redirect if url is outdated
  // For example user share link to weekly-schedule but its already new one published
  // And we need to have consistent url so we can back tracking completing workouts
  // So do not change url names
  getLastUrlPath() !== readable_url &&
    useHistory().push(`/weekly-schedule/${readable_url}`)

  return (
    <div className=''>
      <WeeklyChallenge
        type='weekly'
        prefix={`/weekly-schedule/${readable_url}`}
        isOngoing={true}
        today={new Date()}
        showHeader={true}
      ></WeeklyChallenge>
    </div>
  )
}

export default WeeklyChallenges
