import React from 'react'
import PropTypes from 'prop-types'
import { XIcon } from '@heroicons/react/outline'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import { clearLocalStorageWithPrefix } from '../utils/Common'

const closeTopLine = (data, setData, published_at) => {
  clearLocalStorageWithPrefix('topLineClosed_')
  localStorage.setItem('topLineClosed_' + published_at, true)
  data.topLineClosed = true
  setData({ ...data })
}

const TopLine = ({ text, text_big, link, published_at }) => {
  const { data, setData } = useContext(AppContext)
  return (
    <div>
      {
        <div className='relative bg-black'>
          <div className='max-w-8xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
            <div className='pr-5 sm:text-center sm:px-16'>
              <p className='font-medium text-white'>
                <div className='md:hidden text-center'>{text}</div>
                <div className='hidden md:inline'>{text}</div>
                <div className='block sm:ml-2 sm:inline-block text-center'>
                  <a href={link} className='text-white font-bold underline'>
                    {/* Learn more */}
                    {/* {' '} */}
                    {text_big != '' ? text_big : 'Learn more'}{' '}
                    <span aria-hidden='true'>&rarr;</span>
                  </a>
                </div>
              </p>
            </div>
            <div className='absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start'>
              <button
                onClick={() => closeTopLine(data, setData, published_at)}
                className='flex p-2 rounded-md hover:bg-black-900'
              >
                <span className='sr-only'>Dismiss</span>
                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

TopLine.defaultProps = {
  text: '',
  text_big: '',
  link: '',
  published_at: '',
}

TopLine.propTypes = {
  text: PropTypes.string.isRequired,
  text_big: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
}

export default TopLine
