/* This example requires Tailwind CSS v2.0+ */
import AppContext from '../context/AppContext'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useContext } from 'react'
import { XIcon } from '@heroicons/react/outline'
import Constants from '../utils/Constants'

export default function Tutorial() {
  const [open, setOpen] = useState(true)
  const [step, setStep] = useState(0)
  const [isVideoCover, setIsVideoCover] = useState(true)
  const { data } = useContext(AppContext)
  const { user } = data

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                <button
                  type='button'
                  className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div>
                {step === 0 && (
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-semibold text-gray-900'
                    >
                      Hey {user.firstName}, welcome to Withsara
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-base font-medium text-gray-500'>
                        Before you start a tour of Withsara, here is a short
                        welcome message to you.
                      </p>
                      <div className='mt-5'>
                        <div className='mt-5 relative'>
                          <div className=' align-top relative justify-start flex flex-wrap aspect-w-16 aspect-h-9'>
                            {!isVideoCover && (
                              <iframe
                                src={Constants.TUTORIAL_VIDEO}
                                frameBorder='0'
                                allow='autoplay; fullscreen; picture-in-picture'
                                allowFullScreen
                              ></iframe>
                            )}
                            {isVideoCover && (
                              <div className='absolute sm:w-full align-top justify-start flex flex-wrap aspect-w-16 aspect-h-9'>
                                <img
                                  className='shadow w-full h-full object-center rounded-xl sm:rounded object-cover lg:w-full lg:h-full'
                                  src={Constants.TUTORIAL_VIEW_THUMB}
                                  alt=''
                                />
                                <div className='absolute z-10 h-10 w-10 sm:h-32 sm:w-32 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                  <svg
                                    onClick={() => {
                                      setIsVideoCover(false)
                                    }}
                                    className='abosulte text-white cursor-pointer h-10 w-10 sm:h-full sm:w-full'
                                    viewBox='0 0 32 32'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M25.2323 16.065C25.5703 16.2565 25.5703 16.7435 25.2323 16.935L11.7465 24.577C11.4132 24.7659 11 24.5251 11 24.142L11 8.85803C11 8.47492 11.4132 8.23414 11.7465 8.42302L25.2323 16.065Z'
                                      fill='white'
                                    />
                                    <circle
                                      cx='16'
                                      cy='16'
                                      r='15'
                                      stroke='white'
                                      strokeWidth='2'
                                    />
                                  </svg>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 1 && (
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-semibold text-gray-900'
                    >
                      Find the class that’s right for you
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-base font-medium text-gray-500'>
                        {`Whatever your body's asking for you'll find the class that is right for you. Select from 9 class types or get motivated with curated programs and challenges.`}
                      </p>
                      <div className='mt-5'>
                        <div className='aspect-w-16 aspect-h-9 group-hover:opacity-75'>
                          <img
                            src={Constants.TUTORIAL_IMG_STEP_2}
                            className='shadow w-full h-full object-center object-cover rounded'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-semibold text-gray-900'
                    >
                      The Weekly schedule is member favorite
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-base font-medium text-gray-500'>
                        A weekly plan arrives every Monday for you to follow,
                        filled with the newest workouts on Withsara from all
                        class categories.
                      </p>
                      <div className='mt-5'>
                        <div className='aspect-w-16 aspect-h-9 group-hover:opacity-75'>
                          <img
                            src={Constants.TUTORIAL_IMG_STEP_1}
                            className='shadow w-full h-full object-center object-cover rounded'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='mt-5 sm:mt-6 grid sm:grid-cols-2 gap-3 sm:grid-flow-row-dense'>
                {step === 0 && (
                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm'
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Skip
                  </button>
                )}
                {step > 0 && (
                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 sm:mt-0 sm:col-start-1 sm:text-sm'
                    onClick={() => {
                      setStep(step - 1)
                      setIsVideoCover(true)
                    }}
                    ref={cancelButtonRef}
                  >
                    Back
                  </button>
                )}
                <button
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 sm:col-start-2 sm:text-sm'
                  onClick={() =>
                    step < 2 ? setStep(step + 1) : setOpen(false)
                  }
                >
                  {step < 2 ? 'Next' : 'Done'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
