import React from 'react'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import { Fragment, useState, useEffect } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MonthlyNavigation = ({
  monthly_challenge,
  challenges_current_week,
  setWeek,
}) => {
  const { data, setData } = useContext(AppContext)
  const { monthly_text } = data
  return (
    <div>
      <div className='hidden sm:flex flex-wrap justify-center items-center p-0.5 '>
        <div className='bg-gray-100 sm:rounded-md p-0.5 inline-block mx-auto'>
          <nav className='flex justify-center' aria-label='Tabs'>
            {monthly_challenge?.weekly_challenges?.map(
              (weekly_challenge, index) => (
                <div key={`monthly_nav_item_${weekly_challenge.id}-${index}`}>
                  <div
                    onClick={() => {
                      setWeek(weekly_challenge, index)
                    }}
                    className={`cursor-pointer px-3 py-1 font-medium text-sm rounded-md ${
                      challenges_current_week?.id === weekly_challenge?.id
                        ? 'bg-white text-gray-900 border font-medium border-gray-300'
                        : 'bg-gray-100 text-gray-900 border border-gray-100'
                    }`}
                  >
                    {`Week ${index + 1}`}
                  </div>
                </div>
              )
            )}
          </nav>
        </div>
      </div>
      <div className='block sm:hidden px-3'>
        <Listbox
          value={monthly_text}
          onChange={({ weekly_challenge, index }) => {
            data.monthly_text = {
              name: `Week ${index + 1} of ${
                monthly_challenge?.weekly_challenges?.length
              }`,
            }
            setData({ ...data })
            setWeek(weekly_challenge, index)
          }}
        >
          {({ open }) => (
            <>
              <div className='mt-1 relative'>
                <Listbox.Button className='relative w-auto pl-3 pr-10 py-2 text-left cursor-default sm:text-sm'>
                  <span className='flex items-center'>
                    <CalendarIcon className='flex-shrink-0 h-5 w-5 text-gray-700 rounded-full'></CalendarIcon>
                    <span className='ml-3 block truncate font-semibold text-sm'>
                      {monthly_text.name}
                    </span>
                  </span>
                  <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                    <ChevronDownIcon
                      className='h-5 w-5 mt-1 text-gray-400'
                      aria-hidden='true'
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                    {monthly_challenge?.weekly_challenges?.map(
                      (weekly_challenge, index) => (
                        <Listbox.Option
                          key={weekly_challenge.id}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-gray-900'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={{ weekly_challenge, index }}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className='flex items-center'>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate'
                                  )}
                                >
                                  {`Week ${index + 1}`}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-gray-900',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      )
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  )
}
MonthlyNavigation.defaultProps = {
  monthly_challenge: {},
  challenges_current_week: {},
  setWeek: () => {},
  isFavoriteBadge: true,
}
MonthlyNavigation.propTypes = {
  monthly_challenge: PropTypes.object.isRequired,
  challenges_current_week: PropTypes.object.isRequired,
  setWeek: PropTypes.func.isRequired,
}

export default MonthlyNavigation
