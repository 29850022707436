import { useContext } from 'react'
import AppContext from '../context/AppContext'
import { Link } from 'react-router-dom'
import { BellIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { markNotifcationAsRead } from '../utils/Common'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Notifications() {
  const { data, setData } = useContext(AppContext)
  let { news, newMessage } = data

  if (news && news.length > 5) {
    news = news.slice(0, 5)
  }

  const closeNotification = () => {
    markNotifcationAsRead(news[0].published_at, setData, data)
  }
  if (news && news.leave > 3) {
    news = news.slice(0, 3)
  }
  return (
    <Popover className='relative'>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'hidden sm:inline-flex group mt-2 bg-white rounded-full items-center text-base font-medium hover:text-gray-900 focus:bg-gray-100'
            )}
          >
            <div className='p-1 rounded-full text-gray-900 hover:bg-gray-100'>
              <span className='sr-only'>View notifications</span>
              <BellIcon className='h-6 w-6' aria-hidden='true' />
              {newMessage && (
                <svg
                  className='ml-5 -mt-6 h-2 w-2 text-red-400 absolute'
                  fill='currentColor'
                  viewBox='0 0 8 8'
                >
                  <circle cx={4} cy={4} r={3} />
                </svg>
              )}
            </div>
          </Popover.Button>
          <Link
            to='/news'
            onClick={() => {
              closeNotification()
            }}
            className='inline-flex sm:hidden p-1 rounded-full text-gray-900 hover:bg-gray-100'
          >
            <span className='sr-only'>View notifications</span>
            <BellIcon className='h-6 w-6' aria-hidden='true' />
            {newMessage && (
              <svg
                className='absolute ml-5 mt-0 h-2 w-2 text-red-400'
                fill='currentColor'
                viewBox='0 0 8 8'
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </Link>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute -left-56 sm:-right-10 z-20 mt-3 px-2 w-screen max-w-xs sm:px-0'>
              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                <div className='bg-white divide-y'>
                  {news?.map((item, index) => (
                    <div
                      key={item.name + '_' + index}
                      className='hover:bg-gray-50 '
                    >
                      <Popover.Button>
                        <Link
                          to={`/news/${item.id}`}
                          onClick={() => {
                            closeNotification()
                          }}
                          className='p-5 text-left block rounded-md transition ease-in-out duration-150'
                        >
                          <p
                            className={classNames(
                              index === 0 && newMessage === true
                                ? 'font-extrabold'
                                : '',
                              'text-base font-medium text-gray-900'
                            )}
                          >
                            {item.name}
                          </p>

                          <p
                            className={classNames(
                              index === 0 && newMessage === true
                                ? 'font-bold'
                                : '',
                              'mt-1 text-sm text-gray-500'
                            )}
                          >
                            {item.description}
                          </p>
                        </Link>
                      </Popover.Button>
                    </div>
                  ))}
                  <div></div>
                </div>
                <div className='px-5 bg-gray-50 sm:px-8 sm:py-5'>
                  <div className='text-sm text-center'>
                    <Popover.Button>
                      <Link
                        to='/news'
                        onClick={() => {
                          open = false
                          closeNotification()
                        }}
                        className='font-medium text-gray-500 hover:text-gray-500 transition ease-in-out duration-150'
                      >
                        View all posts <span aria-hidden='true'>&rarr;</span>
                      </Link>
                    </Popover.Button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
