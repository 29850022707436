import PropTypes from 'prop-types'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import React from 'react'
import { isWeekDayCompleted } from '../utils/Common'
import { CheckIcon } from '@heroicons/react/outline'

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const WeekNavigation = ({ week, onDayClick, selectedIndex, prefix }) => {
  const { data } = useContext(AppContext)
  return (
    <div className='content-center mb-4'>
      <div className='hidden sm:flex flex-wrap items-center space-x-7 self-center justify-center'>
        {week.map((day, index) => (
          <div key={`day_${day?.weeklyChallengeId}-${index}`}>
            <div
              onClick={(event) => onDayClick(event, index)}
              className={`${
                selectedIndex === index ? 'border-2 border-gray-900' : 'border'
              } relative cursor-pointer inline-block sm:flex sm:flex-col items-center px-8 py-2 w-28 my-2 border-gray-300 text-sm font-medium rounded-lg text-gray-500 bg-white hover:bg-gray-100`}
            >
              {isWeekDayCompleted(
                `${prefix}/c${day.weeklyChallengeId}`,
                data
              ) && (
                <div className='absolute right-0 justify-end mr-2.5 -mt-1 h-3.5 w-3.5'>
                  <CheckIcon className='sm:mt-1.5 h-3.5 w-3.5 bg-greeny-500 rounded-full text-white'></CheckIcon>
                </div>
              )}
              <div className='text-gray-500 text-base font-medium pt-1.5'>
                {weekDays[day.date.getDay()]}
              </div>
              <div className='text-4xl font-medium text-gray-900 -mt-1.5'>
                {day.date.getDate()}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Mobile */}
      <div className='sm:hidden space-x-5 flex flex-row overflow-y-hidden overflow-x-auto h-15 scrolling-touch no-scrollbar border-t border-b border-gray-200 py-3 px-5'>
        {week.map((day, index) => (
          <div
            key={`day_${day.weeklyChallengeId}-${index}`}
            className='relative'
          >
            <div
              id={`week_nav_${index}`}
              onClick={(event) => {
                onDayClick(event, index)
              }}
              className={`${
                selectedIndex === index ? 'border-gray-500' : ''
              } relative flex flex-col items-center py-1 border border-gray-300 text-sm font-medium rounded-lg text-gray-500 bg-white`}
            >
              <div className='h-1.5  w-full flex justify-end'>
                {isWeekDayCompleted(
                  `${prefix}/c${day.weeklyChallengeId}`,
                  data
                ) && (
                  <CheckIcon className='w-1.5 mr-1 bg-greeny-500 rounded-full text-white'></CheckIcon>
                )}
              </div>
              <div className='flex px-3 -mt-1.5'>
                <div className='text-gray-500 text-base'>
                  {weekDays[day.date.getDay()]}
                </div>
                <div className='ml-1 text-base font-bold text-gray-900'>
                  {day.date.getDate()}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

WeekNavigation.defaultProps = {
  week: [],
  onDayClick: () => {},
  selectedIndex: -1,
  autoDaySelection: false,
  prefix: '',
}
WeekNavigation.propTypes = {
  week: PropTypes.array,
  onDayClick: PropTypes.func,
  selectedIndex: PropTypes.number,
  prefix: PropTypes.string.isRequired,
}

export default WeekNavigation
