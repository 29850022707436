export default {
  MB_PROFILE_LINK: 'https://members.withsara.co/member/sign_in',
  TUTORIAL_VIDEO:
    'https://player.vimeo.com/video/552339091?h=485a39007e&amp;autoplay=1',
  TUTORIAL_VIEW_THUMB: '/hello-from-sara.png',
  TUTORIAL_IMG_STEP_1: '/weekly.png',
  TUTORIAL_IMG_STEP_2: '/9-classes.png',
  SORT_NEWEST: 'Newest',
  SORT_OLDEST: 'Oldest',
  SORT_POPULARITY: 'Popularity',
  WORKOUT_BODY_PARTS: 'workout_body_parts',
  WORKOUT_EQUIPMENTS: 'workout_equipments',
  WORKOUT_GOALS: 'workout_goals',
  WORKOUT_MENSTRUAL_TYPES: 'workout_menstrual_types',
  WORKOUT_INTENSITIES: 'workout_intensity',
  WORKOUT_LEVELS: 'workout_levels',
  WORKOUT_TYPES: 'workout_types',
  WORKOUT_LENGTHS: 'workout_lengths',
  TEACHERS: 'teachers',
  PAGES_PER_PAGE: 40,
  NEW_WORKOUT_CRITERIA_IN_DAYS: 30,
  USE_LOCALSTORAGE_CACHING: false,
  LINK_SHARE:
    '/share?utm_source=app-withsara&utm_medium=link&utm_id=platform&utm_content=share',
  LINK_UPGRADE:
    '/upgrade?utm_source=app-withsara&utm_medium=link&utm_id=platform&utm_content=upgrade',
  CAROUSEL_ITEMS: {
    new: 10,
    types: 10,
    weekly: 10,
    popular: 10,
  },
  CAROUSEL_LINKS: {
    new: 'browse?sortBy=Newest',
    types: 'class-types',
    weekly: 'weekly-classes',
    popular: 'browse?sortBy=Popularity',
  },
  BREAKPOINT_WORKOUTS: {
    180: {
      slidesPerView: 2.5,
      spaceBetween: 20,
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    },
  },
  BREAKPOINT_TYPES: {
    180: {
      slidesPerView: 3.5,
      spaceBetween: 20,
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
    },
    640: {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    },
    768: {
      slidesPerView: 6,
      spaceBetween: 30,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    },
  },
}
