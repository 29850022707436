import { SparklesIcon, UserAddIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

const actions = [
  {
    title: 'Upgrade to annual',
    buttonText: 'Upgrade',
    desctiption: 'Save $229 a year when you upgrade to annual.',
    icon: SparklesIcon,
    iconForeground: 'text-white',
    iconBackground: 'bg-gray-900',
    link: 'upgrade',
  },
  {
    title: 'Train together with a friend',
    buttonText: 'Invite',
    desctiption:
      'Invite a friend to Withsara and get a free month, both for you and your friend when she signs up.',
    icon: UserAddIcon,
    iconForeground: 'text-white',
    iconBackground: 'bg-gray-900',
    link: 'share',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CallToActionBlock() {
  return (
    <div className='container'>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4 sm:mt-12'>
        {actions.map((action, actionIdx) => (
          <Link to={action.link} key={action.title + '_' + actionIdx}>
            <div
              className={classNames(
                'cursor-pointer rounded-lg border border-gray-200 sm:h-56',
                'w-full relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500'
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                  )}
                >
                  <action.icon className='h-5 w-5' aria-hidden='true' />
                </span>
              </div>
              <div className='mt-8'>
                <div className='text-base font-bold'>{action.title}</div>
                <p className='mt-2 text-sm text-gray-500'>
                  {action.desctiption}
                </p>
              </div>
              <span
                className=' absolute top-6 right-6 text-gray-500 group-hover:text-gray-500'
                aria-hidden='true'
              >
                <button className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-900 hover:bg-gray-700'>
                  {action.buttonText}
                </button>
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
