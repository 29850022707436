import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Constants from '../utils/Constants'
import { getOnlySearchableWorkouts } from '../utils/Common'
import Carousel from '../components/Carousel'

const NewClassesThisMonth = () => {
  const { data } = useContext(AppContext)
  let { workouts } = data
  const searchableWorkouts = getOnlySearchableWorkouts(workouts)

  searchableWorkouts.sort((a, b) => {
    return new Date(b.published_at) - new Date(a.published_at)
  })

  return (
    <div className='mt-3.5'>
      <Carousel
        name='New classes this month'
        link={Constants.CAROUSEL_LINKS.new}
        itemsCount={Constants.CAROUSEL_ITEMS.new}
        data={searchableWorkouts}
        type='workout'
        breakPoints={Constants.BREAKPOINT_WORKOUTS}
      />
    </div>
  )
}

export default NewClassesThisMonth
