import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Constants from '../utils/Constants'
import Carousel from '../components/Carousel'
import { getOnlySearchableWorkouts } from '../utils/Common'

const LINK_TO_FULL_INFO = `browse?sortBy=${Constants.SORT_POPULARITY}`

const MostPopular = () => {
  const { data } = useContext(AppContext)
  let { workoutsMostPopular } = data
  if (workoutsMostPopular && workoutsMostPopular.length > 0) {
    // Sort by popularity
    workoutsMostPopular = workoutsMostPopular.sort(
      (a, b) => b.favorite_for_users.length - a.favorite_for_users.length
    )
  }

  const searchableWorkouts = getOnlySearchableWorkouts(workoutsMostPopular)

  return (
    <div>
      <Carousel
        name='Most popular last 30 days'
        link={LINK_TO_FULL_INFO}
        itemsCount={Constants.CAROUSEL_ITEMS.popular}
        data={searchableWorkouts}
        type='workout'
        breakPoints={Constants.BREAKPOINT_WORKOUTS}
      />
    </div>
  )
}

export default MostPopular
