import AppContext from '../context/AppContext'
import { useContext } from 'react'
import PromotialBanner from './PromotialBanner'

const UserIntro = () => {
  const { data } = useContext(AppContext)
  const { user, banner } = data
  return (
    <div>
      <div className='container'>
        <div className='hidden sm:block'>
          <header className='pt-10 pb-7 mt-4 border-b border-gray-200'>
            <h1 className='text-4xl font-bold leading-tight text-gray-900 truncate'>
              Welcome {user?.firstName}
            </h1>
          </header>
        </div>
      </div>
      {banner && banner.visible && (
        <PromotialBanner
          text={banner?.text}
          image={banner?.image}
          link={banner?.link}
        ></PromotialBanner>
      )}
    </div>
  )
}

export default UserIntro
