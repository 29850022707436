import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const NewsCard = ({ id, name, text, date }) => {
  return (
    <div className='w-full'>
      <div className='border border-gray-100 rounded-xl sm:rounded-lg bg-white'>
        <Link to={`/news/${id}`} className='space-y-4'>
          <div className='flex flex-col p-5  '>
            <div className='text-gray-500 text-sm'>
              {new Date(date).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </div>
            <div className='text-lg font-semibold mt-2'>{name}</div>
            <div className='text-gray-500 font-normal mt-2 sm:line-clamp-3'>
              {text}
            </div>
            <div className='text-blue-400 hover:underline cursor-pointer mt-2 font-medium text-sm sm:text-base'>
              Read more
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

NewsCard.defaultProps = {
  id: 0,
  name: '',
  text: '',
  date: '',
}

NewsCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default NewsCard
