import PropTypes from 'prop-types'
import { getWorkoutDoneTime, isWorkoutCompleted } from '../utils/Common'
import { Link } from 'react-router-dom'
import AppContext from '../context/AppContext'
import { CheckIcon } from '@heroicons/react/outline'
import { useContext } from 'react'
import WorkoutBadges from './WorkoutBadges'
const BASE_URL = process.env.REACT_APP_API_URL
const WorkoutWithTime = ({ workoutId, text, url, prefix }) => {
  const { data } = useContext(AppContext)

  const done_date = getWorkoutDoneTime(data, prefix, workoutId)
  const { user, workouts } = data
  const workout = workouts.find((w) => w.id === workoutId)
  return (
    <div>
      {workout && workout.id && (
        <div className='mb-6'>
          <Link
            to={`${prefix}/workout/${workout?.id}`}
            className='flex flex-row space-x-3 sm:space-x-7 group'
          >
            <div className='w-6/12'>
              <div className='aspect-w-16 aspect-h-9'>
                <img
                  className='w-full h-full object-center object-cover lg:w-full lg:h-full rounded-lg sm:rounded group-hover:opacity-75'
                  src={`${BASE_URL}${workout?.image?.url}`}
                  alt=''
                />
              </div>
            </div>
            <div className='w-6/12'>
              <div className='flex'>
                <div className='h3 pt-1 sm:pt-3 text-sm sm:text-xl inline-block font-semibold line-clamp-2 '>
                  {workout?.name}
                </div>
              </div>
              <div className='badges'>
                <WorkoutBadges workout={workout} />
              </div>
              <div className='mt-2'>
                <h3 className='h3 text-tiny font-normal text-gray-500 sm:mb-2 line-clamp-1 sm:line-clamp-3'>
                  {workout?.description}
                </h3>
              </div>
              <div>
                {done_date && (
                  <div className='text-xs sm:text-sm text-gray-500 flex flex-wrap sm:flex-row space-x-2'>
                    <div className='pr-0.5 sm:pr-2 flex'>
                      {isWorkoutCompleted(workout, data, prefix) && (
                        <div className='h-2 w-2 mr-2 sm:h-3 sm:w-3 content-center align-top items-center'>
                          <CheckIcon className='w-2 mt-2.5 sm:w-3 sm:mt-1.5 bg-greeny-500 rounded-full text-white'></CheckIcon>
                        </div>
                      )}
                      <div className='mt-1.5 sm:mt-1 text-gray-400 text-xs sm:text-sm'>
                        {text}{' '}
                        {new Date(done_date).toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

WorkoutWithTime.defaultProps = {
  workoutId: 1,
  text: 'Completed',
  url: '',
  prefix: '',
}
WorkoutWithTime.propTypes = {
  workoutId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
}

export default WorkoutWithTime
