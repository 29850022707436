// This Utils is in charge of authetication and handling JWT
// When user come to app.   its check that token is valid and that it
// If not exists, try to sign
// If not user exist -> create a new one ans sing in

// with sign in or sign up its strore token in local storage
// if User sign out it just delete token from local storage
import jwt_decode from 'jwt-decode'
const BASE_URL = process.env.REACT_APP_API_URL

const signInIfNeeded = async (email, password, username) => {
  console.log('🚪 Login ')
  // if (token && isValidJWT(token)) {
  //   console.log('token is valid, no need for any action')
  //   return true
  // }

  // if (token === null || token === undefined || !isValidJWT(token)) {
  //   console.log('no token or token is expired')
  //   console.log('try to sign in')
  if (!(await signIn(email, password))) {
    if (!(await signUp(email, password, username))) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
  // return false;
  // }
  // return false
}

const isValidJWT = (token) => {
  try {
    const ecoded = jwt_decode(token)
    console.log(ecoded)
    if (ecoded.exp < Date.now() / 1000) {
      return false
    }
  } catch (error) {
    return false
  }
  return true
}

const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null
    return localStorage.setItem(key, value)
  },
  getItem: async function (key) {
    await null
    return localStorage.getItem(key)
  },
}

const saveToken = async (token) => {
  await asyncLocalStorage.setItem('token', token)
  console.log('token is set')
  return true
}

const signOut = () => {
  localStorage.removeItem('token')
}

const signIn = async (email, password) => {
  console.log(' >> Try to sign in')
  try {
    const res = await fetch(`${BASE_URL}/auth/local`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ identifier: email, password: password }),
    })
    if (res.status !== 200) {
      return false
    }
    const data = await res.json()
    return await saveToken(data.jwt)
  } catch (error) {
    return false
  }
}
const getProfile = async () => {
  try {
    const res = await fetch(`${BASE_URL}/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    if (res.status !== 200) {
      return false
    }
    return await res.json()
  } catch (error) {
    return false
  }
}

const signUp = async (email, password, username) => {
  console.log(' >>> Try to sign up')
  try {
    const res = await fetch(`${BASE_URL}/auth/local/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password: password, username: username }),
    })
    if (res.status !== 200) {
      return false
    }
    const data = await res.json()
    return await saveToken(data.jwt)
    // return true
  } catch (error) {
    return false
  }
}

export { signInIfNeeded, signOut, getProfile }
