import AppContext from '../context/AppContext'
import { isNoActive } from '../utils/Common'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import MainHeadline from '../components/MainHeadline'
import WeekNavigation from '../components/WeekNavigation'
import WorkoutWithTime from '../components/WorkoutWithTime'
import WeekNavigationSimple from '../components/WeekNavigationSimple'
import { SignUpComponent } from '../components/SignUpComponent'

const getWeekArray = (date, challenges) => {
  const weekArray = []
  if (challenges) {
    const startDay = date.getDay()
    const _startDateCopy = new Date(date)
    _startDateCopy.setDate(date.getDate() - startDay + 1)
    for (let i = 0; i < 7; i++) {
      weekArray.push({
        date: new Date(_startDateCopy),
        weeklyChallengeId: challenges.challenges[i]
          ? challenges.challenges[i].id
          : i,
      })
      _startDateCopy.setDate(_startDateCopy.getDate() + 1)
    }
  }
  return weekArray
}

const WeeklyChallenge = ({ prefix, type, today, showHeader, isOngoing }) => {
  const { data, setData } = useContext(AppContext)
  let {
    weekly_challenges,
    selectedChallenge,
    challenges_current_week,
    weekly_current_schedule,
  } = data
  const recent_weekly_challenge = weekly_challenges[0]

  const challenges =
    type === 'weekly' ? weekly_current_schedule : challenges_current_week

  const week = getWeekArray(today, challenges)

  const onDayClick = (event, index) => {
    try {
      const divElement = document.getElementById(`challenge_${index}`)
      divElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    } catch (error) {
      console.error(error)
    }
    data.selectedChallenge = index
    setData({ ...data })
  }

  return (
    <div>
      {showHeader && (
        <MainHeadline
          title={recent_weekly_challenge?.name}
          desciption={recent_weekly_challenge?.description}
          dynamicHeader={false}
        ></MainHeadline>
      )}
      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}

      {!isNoActive(data) && (
        <div>
          {/* <div className=''> */}
          <div className='justify-evenly sm:justify-center mt-5'>
            {isOngoing === true && (
              <WeekNavigation
                prefix={prefix}
                week={week}
                onDayClick={onDayClick}
                selectedIndex={selectedChallenge}
              ></WeekNavigation>
            )}
            {isOngoing !== true && (
              <WeekNavigationSimple
                prefix={prefix}
                week={week}
                onDayClick={onDayClick}
                selectedIndex={selectedChallenge}
              ></WeekNavigationSimple>
            )}
          </div>
          <div className='containerSmall'>
            <div className='px-1 sm:px-0 mx-auto align-top justify-start flex flex-col'>
              {challenges?.challenges?.map((challenge, index) => (
                <div
                  key={`weekly_challenge_${challenge?.id}-${index}`}
                  id={`challenge_${index}`}
                  className={`p-3 -ml-2 sm:-ml-0 sm:p-5 ${
                    selectedChallenge === index
                      ? 'border border-gray-300 bg-white rounded-lg'
                      : ''
                  }`}
                >
                  <div className='font-semibold sm:font-semibold text-sm sm:text-base mb-2 sm:mb-5'>
                    {isOngoing !== true && 'Day ' + (index + 1)}
                    {isOngoing === true &&
                      week[index].date.toLocaleDateString('en-US', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                      })}
                  </div>
                  {challenge?.workouts?.map((workout, index) => (
                    <div
                      key={`challenge_${challenge.id}_workout_${workout?.id}-${index}`}
                    >
                      <WorkoutWithTime
                        prefix={`${prefix}/c${challenge.id}`}
                        workoutId={workout.id}
                      ></WorkoutWithTime>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

WeeklyChallenge.defaultProps = {
  prefix: '',
  type: 'weekly',
  urlToWorkout: '',
  today: new Date(),
  showHeader: false,
  isOngoing: false,
}
WeeklyChallenge.propTypes = {
  prefix: PropTypes.string.isRequired,
  type: PropTypes.string,
  urlToWorkout: PropTypes.string,
  today: PropTypes.instanceOf(Date),
  showHeader: PropTypes.bool,
  isOngoing: PropTypes.bool,
}

export default WeeklyChallenge
