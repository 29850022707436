import UserIntro from '../components/UserIntro'
import CallToActionBlock from '../components/CallToActionBlock'
import NewClassesThisMonth from '../panels/NewClassesThisMonth'
import ClassTypes from '../panels/ClassTypes'
import MostPopular from '../panels/MostPopular'
import WeeklySchedule from '../panels/WeeklySchedule'
import Tutorial from '../components/Tutorial'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { SignUpModal } from '../components/SignUpModal'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import { isNoActive } from '../utils/Common'

const ForYou = () => {
  const { data } = useContext(AppContext)
  const [firstTime, setFirstTime] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.search.includes('welcome=1')) {
      setFirstTime(true)
    }
  }, [])
  return (
    <>
      <div>{isNoActive(data) && <SignUpModal></SignUpModal>}</div>
      {firstTime && <Tutorial></Tutorial>}
      <UserIntro></UserIntro>
      <NewClassesThisMonth></NewClassesThisMonth>
      <ClassTypes></ClassTypes>
      <WeeklySchedule></WeeklySchedule>
      <MostPopular></MostPopular>
      <CallToActionBlock></CallToActionBlock>
    </>
  )
}

export default ForYou
