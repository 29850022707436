import React from 'react'
import PropTypes from 'prop-types'

const WorkoutBadges = ({ workout, isNew, isHideWorkoutBody }) => {
  return (
    <div className='text-xs sm:text-tiny font-base text-gray-500 flex truncate sm:flex-row space-x-1 sm:space-x-1.5'>
      {isNew && <div>New</div>}
      {isNew && <div className='transform scale-75'> • </div>}
      {workout?.workout_types?.length > 0 && (
        <div className=''>{workout?.workout_types[0]?.name}</div>
      )}
      {workout?.workout_types?.length > 0 && (
        <div
          className={`${
            isHideWorkoutBody ? 'hidden sm:block' : ''
          } transform scale-75`}
        >
          {' '}
          •{' '}
        </div>
      )}
      {workout?.workout_body_parts?.length > 0 && (
        <div className={`${isHideWorkoutBody ? 'hidden sm:block' : ''}`}>
          {workout?.workout_body_parts[0]?.name}
        </div>
      )}
      {workout?.workout_body_parts?.length > 0 && (
        <div className='transform scale-75'> • </div>
      )}
      {workout?.duration && (
        <div className=''>{Math.floor(workout?.duration / 60)} min</div>
      )}
    </div>
  )
}
WorkoutBadges.defaultProps = {
  workout: {},
  isNew: false,
  isHideWorkoutBody: true,
}
WorkoutBadges.propTypes = {
  workout: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  isHideWorkoutBody: PropTypes.bool,
}

export default WorkoutBadges
