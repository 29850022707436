import './App.css'
import NavBar from './components/NavBar'
import { useHistory } from 'react-router-dom'
import TopLine from './components/TopLine'
import Footer from './components/Footer'
import { signInIfNeeded, getProfile } from './api/AuthUtils'
import { useState, useEffect } from 'react'
import { AppProvider } from './context/AppContext'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Loader from './components/Loader'
import { getDataByEndPoint } from './services/Api'

import {
  createFilterObject,
  getDayIndexMondaySunday,
  getWorkoutFavorites,
  getWorkoutHistory,
} from './utils/Common'
import NavBarOnlyMoble from './components/NavBarOnlyMobile'
import Constants from './utils/Constants'
import constants from './utils/Constants'
import ScrollToTop from './components/ScrollTop'
import MemberSpaceComponent from './components/MemberSpaceComponent'

import WorkoutFull from './pages/WorkoutFull'
import NewsItem from './pages/NewsItem'
import News from './pages/News'
import Challenges from './pages/Challenges'
import Challenge from './pages/Challenge'
import Share from './pages/Share'
import ClassTypesPage from './pages/ClassTypesPage'
import Upgrade from './pages/Upgrade'
import NotFound from './pages/NotFound'
import ForYou from './pages/ForYou'
import Browse from './pages/Browse'
import YourWorkouts from './pages/YourWorkouts'
import WeeklyChallenges from './pages/WeeklyChallenges'
import Login from './pages/Login'
import ReactGA from 'react-ga'

const RODUCTION = process.env.REACT_APP_PRODUCTION

// this two variables should be overwritten by memberspace
const email = 'tes22@yy.ru'
const password = '123456'
var MemberSpace = window.MemberSpace
let isFetchingInProgress = false

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr
  if (this.length === 0) return hash
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

/**
 *
 * @param {Array} memberInfo
 * @returns 'free' or 'subscription'
 */
const getSubsciptionType = (memberInfo) => {
  if (!memberInfo) return 'noActive'
  if (memberInfo.length > 0) {
    for (let i = 0; i < memberInfo.length; i++) {
      const element = memberInfo[i]
      if (element.status === 'active' || element.status === 'trialing') {
        return 'active'
      }
    }
  }
  return 'noActive'
}

const getMsReadyPromise = () =>
  new Promise((resolve) => {
    if (MemberSpace.ready) {
      // Ready event is already fired, so let's not wait for it, it will not be fired again
      resolve(window.MemberSpace.getMemberInfo())
    } else {
      // MS widget is not yet ready, let's subscribe for the event
      const handleReady = ({ detail }) => {
        resolve(detail)
        // Unsubscribe ourselves, this allows GC to collect all related memory
        document.removeEventListener('MemberSpace.ready', handleReady)
      }

      // Listen to ready event
      document.addEventListener('MemberSpace.ready', handleReady)
    }
  })

const getMsMemberInfoPromise = () =>
  new Promise((resolve) => {
    // MS widget is not yet ready, let's subscribe for the event
    const handleReady = ({ detail }) => {
      resolve(detail)
    }

    // Listen to ready event
    document.addEventListener('MemberSpace.member.info', handleReady)
    // }
  })

const getMsMemberLogoutPromise = () =>
  new Promise((resolve) => {
    // MS widget is not yet ready, let's subscribe for the event
    const handleReady = ({ detail }) => {
      resolve(detail)
    }

    // Listen to ready event
    document.addEventListener('MemberSpace.member.logout', handleReady)
  })

const getMsMemberConversionPromise = () =>
  new Promise((resolve) => {
    const handleReady = ({ detail }) => {
      resolve(detail)
    }

    // Listen to ready event
    document.addEventListener('MemberSpace.conversion', handleReady)
  })

function App() {
  const history = useHistory()
  ReactGA.initialize('GTM-55H6LD2')
  ReactGA.pageview(window.location.pathname + window.location.search)

  const [data, setData] = useState({ user: {}, workouts: [] })
  const [loading, setLoading] = useState(true)
  const [isSigned, setSignedIn] = useState(false)
  const [isGuest, setGuest] = useState(false)

  // The rest of the app just works with the data in Memory without any API calls
  useEffect(() => {
    console.log('App starts')

    // run
    getMsReadyPromise().then(({ memberInfo }) => {
      isUserSinged(memberInfo, true)
    })
    getMsMemberInfoPromise().then(({ memberInfo }) => {
      if (isFetchingInProgress && memberInfo != undefined) {
        setLoading(true)
      }
      isUserSinged(memberInfo, false)
    })
    getMsMemberLogoutPromise().then(() => {
      data.userMetaInfo = []
      data.user = {}
      data.subsciptionType = 'noActive'
      data.workout_history = []
      data.workout_favorites = []
      localStorage.removeItem('data')
      localStorage.removeItem('token')
      setData(data)
    })
    getMsMemberConversionPromise().then(({ memberInfo }) => {
      isUserSinged(memberInfo, false)
    })

    const sortWeeklyChallengesInMonthlyChallenges = (monthly_challenges) =>
      monthly_challenges.map((item) => {
        item.weekly_challenges = [...item.weekly_challenges].sort((a, b) => {
          if (a.challenge_name < b.challenge_name) return -1
          if (a.challenge_name > b.challenge_name) return 1
          return 0
        })
        return item
      })

    const fetchData = async () => {
      try {
        isFetchingInProgress = true
        // If login page remove loader and grab data in background
        if (
          window.location.pathname === '/login' &&
          !(data.user && data.user.email)
        ) {
          setLoading(false)
        }

        if (
          constants.USE_LOCALSTORAGE_CACHING &&
          localStorage.getItem('data')
        ) {
          const dataStorage = JSON.parse(localStorage.getItem('data'))
          dataStorage.topLineClosed = true
          setData({ ...dataStorage })
          setLoading(false)
        }
        console.log('🚀 Start fetching data')
        let [
          _workouts,
          _weekly_challenges,
          _news,
          _workout_body_parts,
          _workout_equipments,
          _workout_lengths,
          _workout_goals,
          _workout_intensities,
          _workout_levels,
          _workout_menstrual_types,
          _workout_types,
          _monthly_challenges,
          _banner,
          _topLine,
        ] = await Promise.all([
          getDataByEndPoint('workouts', '_sort=created_at:DESC&_limit=-1'),
          getDataByEndPoint(
            'weekly-challenges',
            '_sort=publish_at:DESC&_limit=1'
          ),
          getDataByEndPoint('news-items', '_sort=created_at:DESC'),
          getDataByEndPoint('workout-body-parts'),
          getDataByEndPoint('workout-equipments'),
          getDataByEndPoint('workout-lengths'),
          getDataByEndPoint('workout-goals'),
          getDataByEndPoint('workout-intensities'),
          getDataByEndPoint('workout-levels'),
          getDataByEndPoint('workout-menstrual-types'),
          getDataByEndPoint(
            'workout-types',
            '_sort=published_at:DESC&_limit=-1'
          ),
          getDataByEndPoint(
            'monthly-challenges',
            '_sort=published_at:DESC&_limit=-1'
          ),
          getDataByEndPoint('banner'),
          getDataByEndPoint('top-line'),
        ])

        console.log('Data fetched')
        // Form weekly challenge workouts
        data.weekly_challenges_workouts = []
        if (_weekly_challenges && _weekly_challenges.length > 0) {
          _weekly_challenges[0].challenges?.map((challenge) =>
            challenge?.workouts?.map((workout) =>
              data.weekly_challenges_workouts.push({
                ...workout,
                ...{ day: challenge.id },
              })
            )
          )
        }
        console.log('weekly_challenges calculated')

        data.weekly_current_schedule = _weekly_challenges[0]
        data.workouts = [..._workouts]
        data.weekly_challenges = [..._weekly_challenges]
        data.workoutsMostPopular = [..._workouts]

        data.news = [..._news]

        console.log('News done')

        if (_news.length > 0) {
          if (!localStorage.getItem('recentNews_' + _news[0].published_at)) {
            data.newMessage = true
          }
        }

        data.banner = { ..._banner }
        data.topLine = { ..._topLine }
        console.log('Banner and topLine done')

        data.topLineClosed = !!localStorage.getItem(
          'topLineClosed_' + _topLine.published_at
        )

        data.monthly_challenges = sortWeeklyChallengesInMonthlyChallenges([
          ..._monthly_challenges,
        ])
        console.log('monthly_challenges done')
        console.log(data.monthly_challenges)

        //TODO:test that this is working
        data.workoutsFiltered = [..._workouts]
        data.workoutsFilteredPaginated = [..._workouts].slice(
          0,
          Constants.PAGES_PER_PAGE
        )

        data.workout_body_parts = [..._workout_body_parts]
        data.workout_equipments = [..._workout_equipments]
        data.workout_goals = [..._workout_goals]
        data.workout_intensities = [..._workout_intensities]
        data.workout_levels = [..._workout_levels]
        data.workout_menstrual_types = [..._workout_menstrual_types]
        data.workout_types = [..._workout_types]

        // Workout History
        data.workout_history = getWorkoutHistory(data.userMetaInfo)
        data.workout_favorites = getWorkoutFavorites(data.user)

        // Filters
        const _filters = createFilterObject({
          _workout_body_parts,
          _workout_equipments,
          _workout_lengths,
          _workout_goals,
          _workout_intensities,
          _workout_levels,
          _workout_types,
          _workout_menstrual_types,
        })
        console.log('Filters computed')
        console.log(_filters)
        data.filters = [..._filters]
        data.activeFilters = []
        data.sortOptions = [
          { name: constants.SORT_NEWEST, current: true },
          { name: constants.SORT_OLDEST, current: false },
          { name: constants.SORT_POPULARITY, current: false },
        ]

        data.workout_your_tabs = [
          { name: 'Favorite workouts', current: true },
          { name: 'Workout history', current: false },
        ]

        data.page = 1
        const today = new Date()
        const index = getDayIndexMondaySunday(today)
        // Sunday - Saturday : 0 - 6
        // Monday - Sunday : 0 - 6
        data.selectedChallenge = index
        if (
          constants.USE_LOCALSTORAGE_CACHING &&
          !localStorage.getItem('data')
        ) {
          setLoading(false)
        }
        setData({ ...data })
        console.log('✅ Done')
        isFetchingInProgress = false

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    const isUserSinged = async (userMemberSpace, isFetchData) => {
      if (RODUCTION === 'true') {
        // const userMemberSpace = await getMemberSpaceUser()
        if (userMemberSpace) {
          // undefined - guest
          setSignedIn(true)
          const password =
            (userMemberSpace.email + userMemberSpace.id).toString().hashCode() +
            'pwd'.toString()
          const email = userMemberSpace.email
          const result = await signInIfNeeded(email, password, email)
          if (result) {
            // retrieve user data
            const _user = await getProfile()
            if (_user) {
              _user.profileImageUrl = userMemberSpace.profileImageUrl
                ? userMemberSpace.profileImageUrl
                : undefined
              _user.firstName = userMemberSpace.firstName
                ? userMemberSpace.firstName
                : ''
              _user.lastName = userMemberSpace.lastName
                ? userMemberSpace.lastName
                : ''
              _user.memberships = userMemberSpace.memberships

              const _userMetaInfo = await getDataByEndPoint(
                'meta-user-infos',
                '_sort=action_time:DESC',
                1
              )
              console.log('_userMetaInfo', _userMetaInfo)
              data.subsciptionType = getSubsciptionType(
                userMemberSpace.memberships
              )
              data.workout_history = getWorkoutHistory(_userMetaInfo)
              data.workout_favorites = getWorkoutFavorites(_user)
              if (Object.keys(_userMetaInfo).length !== 0) {
                data.userMetaInfo = [..._userMetaInfo]
              }
              data.user = { ...data.user, ..._user }
            }
            data.selectedPlanIndex = 0
            setData(data)
            if (isFetchData) fetchData()
          }
        } else {
          data.subsciptionType = 'noActive'
          data.workout_history = []
          data.workout_favorites = []
          data.userMetaInfo = []
          data.selectedPlanIndex = 0
          setSignedIn(true)
          setGuest(true)
          setData(data)
          fetchData()
        }
      } else {
        const result = await signInIfNeeded(email, password, email)
        if (result) {
          setSignedIn(true)
          let _user = await getProfile()
          if (_user === false) {
            _user = {}
          }
          console.log(_user)
          _user.profileImageUrl =
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
          _user.firstName = 'Alexander'
          _user.lastName = 'Stransson'
          data.user = { ...data.user, ..._user }
          data.selectedPlanIndex = 0
          const _userMetaInfo = await getDataByEndPoint(
            'meta-user-infos',
            '_sort=action_time:DESC', // not working because fix on backend
            1
          )
          console.log('_userMetaInfo', _userMetaInfo)
          // data.subsciptionType = getSubsciptionType(userMemberSpace.memberships)
          data.workout_history = getWorkoutHistory(data.userMetaInfo)
          data.workout_favorites = getWorkoutFavorites(data.user)
          if (Object.keys(_userMetaInfo).length !== 0) {
            data.userMetaInfo = [..._userMetaInfo]
          }
          setData(data)
          fetchData()
        }
      }
    }
  }, [])

  return (
    <div className='tracking-tight flex flex-col h-screen bg-gray-50'>
      <BrowserRouter>
        <div className='bg-gray-50 flex-grow'>
          <MemberSpaceComponent />
          <ScrollToTop />
          {loading && <Loader />}
          {isSigned && (
            <AppProvider value={{ data, setData }}>
              {!loading &&
                !data.topLineClosed &&
                data.topLine &&
                data.topLine.visible && (
                  <TopLine
                    text={data.topLine.name}
                    text_big={data.topLine.name_big}
                    link={data.topLine.link}
                    published_at={data.topLine.published_at}
                  ></TopLine>
                )}
            </AppProvider>
          )}
          {!loading && isSigned && (
            <AppProvider value={{ data, setData }}>
              <NavBar></NavBar>
              <NavBarOnlyMoble></NavBarOnlyMoble>
              <div className='relative'>
                {!loading && (
                  <Switch>
                    <Route path='/' component={ForYou} exact></Route>
                    <Route path='/browse' component={Browse}></Route>
                    <Route
                      path='/class-types'
                      component={ClassTypesPage}
                    ></Route>
                    <Route
                      path='/weekly-schedule/:readable_url/:day/workout/:id'
                      component={WorkoutFull}
                    ></Route>
                    <Route
                      path='/challenges/:challengeId/:weekId/:day/workout/:id'
                      component={WorkoutFull}
                    ></Route>
                    <Route
                      path='/weekly-schedule/:readable_url'
                      component={WeeklyChallenges}
                    ></Route>
                    <Route path='/challenges/:id' component={Challenge}></Route>
                    <Route path='/challenges' component={Challenges}></Route>
                    <Route
                      path='/your-workouts'
                      component={YourWorkouts}
                    ></Route>
                    <Route path='/workout/:id' component={WorkoutFull}></Route>
                    <Route path='/news/:id' component={NewsItem}></Route>
                    <Route path='/news' component={News}></Route>
                    <Route path='/share' component={Share}></Route>
                    <Route path='/login' component={Login}></Route>
                    <Route path='/upgrade' component={Upgrade}></Route>
                    <Route component={NotFound}></Route>
                  </Switch>
                )}
              </div>
            </AppProvider>
          )}
        </div>
        {!loading && isSigned && <Footer></Footer>}
      </BrowserRouter>
    </div>
  )
}

export default App
