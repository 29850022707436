import React from 'react'
import ReactMarkdown from 'react-markdown'
import { isNoActive } from '../utils/Common'
import AppContext from '../context/AppContext'
import { useParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import { HeartIcon, CheckCircleIcon, CheckIcon } from '@heroicons/react/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'
import {
  setStateWorkoutDoneStatus,
  setStateWorkoutFavoriteStatus,
} from '../api/StateUtils'
import {
  isWorkoutFavorite,
  isWorkoutCompleted,
  getWorkoutSourceName,
  isPublishedLessThan30Days,
} from '../utils/Common'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import WorkoutInfoBlock from '../components/WorkoutInfoBlock'
import { ShareModal } from '../components/ShareModal'

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import WorkoutBadges from '../components/WorkoutBadges'
import Constants from '../utils/Constants'
import { SignUpComponent } from '../components/SignUpComponent'
import rehypeRaw from 'rehype-raw'

const BASE_URL = process.env.REACT_APP_API_URL
const WorkoutFull = () => {
  const { id } = useParams()

  const source_name = getWorkoutSourceName()

  const [open, setOpen] = useState(false)

  const [isVideoCover, setVideoCover] = useState(true)
  const [isShareModalOpen, setShareMoalOpen] = useState(false)

  const { data, setData } = useContext(AppContext)
  const { workouts, user } = data
  const history = useHistory()
  let workout = {}
  if (workouts && workouts.length > 0) {
    workout = workouts.find((workout) => workout.id.toString() === id)
  }
  return (
    <div className='container'>
      <div className='pt-5'>
        {history.action !== 'POP' && (
          <div
            className='text-gray-500 flex -ml-1 space-x-1 cursor-pointer'
            onClick={() => {
              history.goBack()
            }}
          >
            <ChevronLeftIcon className='h-3.5 w-3.5 mt-0.5 sm:h-5 sm:w-5  text-gray-300'></ChevronLeftIcon>
            <div className='text-sm sm:text-base'>Back</div>
          </div>
        )}
      </div>

      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}
      {!isNoActive(data) && (
        <div className='containerSmall'>
          <div className='mt-5 relative'>
            <div className=' align-top relative justify-start flex flex-wrap aspect-w-16 aspect-h-9'>
              {workout && workout.video_code && !isVideoCover && (
                <iframe
                  src={`${workout?.video_code}`}
                  frameBorder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowFullScreen
                ></iframe>
              )}
              {isVideoCover && (
                <div className='absolute sm:w-full align-top justify-start flex flex-wrap aspect-w-16 aspect-h-9'>
                  {workout && workout.image && workout.image.url && (
                    <img
                      className='w-full h-full object-center rounded-xl sm:rounded object-cover lg:w-full lg:h-full'
                      src={`${BASE_URL}${workout.image.url}`}
                      alt=''
                    />
                  )}
                  <div className='absolute z-10 h-10 w-10 sm:h-32 sm:w-32 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <svg
                      onClick={() => {
                        setVideoCover(false)
                      }}
                      className='abosulte text-white cursor-pointer h-10 w-10 sm:h-full sm:w-full'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M25.2323 16.065C25.5703 16.2565 25.5703 16.7435 25.2323 16.935L11.7465 24.577C11.4132 24.7659 11 24.5251 11 24.142L11 8.85803C11 8.47492 11.4132 8.23414 11.7465 8.42302L25.2323 16.065Z'
                        fill='white'
                      />
                      <circle
                        cx='16'
                        cy='16'
                        r='15'
                        stroke='white'
                        strokeWidth='2'
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className='flex flex-col sm:flex-row sm:space-y-0 justify-between py-5'>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-col '>
                  <div className='space-x-1 sm:space-x-4 flex '>
                    {isWorkoutCompleted(workout, data, source_name) && (
                      <div className='h-3 w-4 sm:h-3 sm:w-3 content-center align-top items-center'>
                        <CheckIcon className='w-3 mt-1.5 sm:w-3.5 sm:mt-2 bg-greeny-500 rounded-full text-white'></CheckIcon>
                      </div>
                    )}
                    <h3 className='h3 text-base sm:text-xl inline-block font-extrabold'>
                      {workout?.name}
                    </h3>
                  </div>
                  <WorkoutBadges
                    workout={workout}
                    isNew={isPublishedLessThan30Days(workout?.published_at)}
                    isHideWorkoutBody={false}
                  />
                </div>
                <div className='block sm:hidden w-10'>
                  <div className='sm:transition-transform duration-400 sm:transform sm:hover:opacity-75'>
                    {isWorkoutFavorite(user, workout) ? (
                      <HeartIconSolid
                        className={`cursor-pointer rounded h-10 w-10 p-2 hover:bg-gray-200`}
                        onClick={() =>
                          setStateWorkoutFavoriteStatus(
                            user,
                            workout,
                            data,
                            setData
                          )
                        }
                      />
                    ) : (
                      <HeartIcon
                        className={`cursor-pointer rounded  h-10 w-10 p-2 hover:bg-gray-200`}
                        onClick={() =>
                          setStateWorkoutFavoriteStatus(
                            user,
                            workout,
                            data,
                            setData
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='flex space-x-0 sm:space-x-5 mt-5'>
                <div className='hidden sm:block'>
                  <div className='sm:transition-transform duration-400 sm:transform sm:hover:opacity-75'>
                    {isWorkoutFavorite(user, workout) ? (
                      <HeartIconSolid
                        className={`cursor-pointer rounded h-10 w-10 p-2 hover:bg-gray-200`}
                        onClick={() =>
                          setStateWorkoutFavoriteStatus(
                            user,
                            workout,
                            data,
                            setData
                          )
                        }
                      />
                    ) : (
                      <HeartIcon
                        className={`cursor-pointer rounded sm:h-10 sm:w-10 p-2 hover:bg-gray-200`}
                        onClick={() =>
                          setStateWorkoutFavoriteStatus(
                            user,
                            workout,
                            data,
                            setData
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <button
                  className={`w-full inline-flex items-center py-1 h-10 justify-center mt-1 sm:mt-0 px-4 sm:px-8 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 truncate 
 ${
   isWorkoutCompleted(workout, data, source_name)
     ? 'text-white bg-greeny-500 hover:bg-greeny-300 focus:ring-greeny-500'
     : 'text-white bg-gray-900 focus:ring-gray-900 hover:bg-gray-700'
 }`}
                  onClick={() => {
                    setStateWorkoutDoneStatus(
                      user,
                      workout,
                      data,
                      setData,
                      source_name
                    )

                    if (isWorkoutCompleted(workout, data, source_name)) {
                      setOpen(true)
                    }
                  }}
                >
                  <CheckCircleIcon
                    className='-ml-3.5 -2 h-10 w-10 p-2 text-white'
                    aria-hidden='true'
                  />
                  {isWorkoutCompleted(workout, data, source_name) ? (
                    <span>Completed</span>
                  ) : (
                    <span className='text-white'>Mark as completed</span>
                  )}
                </button>
              </div>
            </div>
            <div className='text-sm sm:text-base font-bold text-gray-900 mt-4'>
              Description
            </div>
            <div className='pt-5 pb-5 text-gray-500 text-sm sm:text-base'>
              {/* {workout?.description} */}

              {/* TODO: fix links  WIT-34 */}
              <ReactMarkdown
                linkTarget={'_blank'}
                rehypePlugins={[rehypeRaw]}
                className='markdownHtml'
                transformImageUri={(src, alt, title) => `${BASE_URL}${src}`}
                // transformLinkUri={(uri) => `${uri}`}
              >
                {workout?.description}
              </ReactMarkdown>
            </div>
            <div className='text-sm sm:text-base font-bold mt-5 mb-5 text-gray-900'>
              Information
            </div>
            <div className='separator'></div>
            <div className='flex flex-col justify-between divide-y divide-gray-200 mb-20'>
              <WorkoutInfoBlock
                icon='flag'
                title='Goal of this workout'
                list={workout?.workout_goals}
                tagUrl={Constants.WORKOUT_GOALS}
              ></WorkoutInfoBlock>
              {workout?.workout_menstrual_types?.length !== 0 && (
                <WorkoutInfoBlock
                  icon='calendar'
                  title='Menstrual cycle'
                  list={workout?.workout_menstrual_types}
                  tagUrl={Constants.WORKOUT_MENSTRUAL_TYPES}
                ></WorkoutInfoBlock>
              )}
              <WorkoutInfoBlock
                icon='plus'
                title='Equipment'
                tagUrl={Constants.WORKOUT_EQUIPMENTS}
                list={workout?.workout_equipments}
              ></WorkoutInfoBlock>
              <WorkoutInfoBlock
                icon='fire'
                title='Intensity'
                tagUrl={Constants.WORKOUT_INTENSITIES}
                dictionaryId={workout?.workout_intensity?.id}
                text={workout?.workout_intensity?.name}
              ></WorkoutInfoBlock>
              <WorkoutInfoBlock
                icon='hand'
                title='Pre & post-natal'
                tagUrl={Constants.WORKOUT_LEVELS}
                list={workout?.workout_levels}
              ></WorkoutInfoBlock>
              <WorkoutInfoBlock
                icon='music'
                title='Playlist'
                link={workout?.spotify_url}
              ></WorkoutInfoBlock>
              <WorkoutInfoBlock
                icon='share'
                title='Share'
                share={true}
                onShareClicked={() => setShareMoalOpen(true)}
              ></WorkoutInfoBlock>
              <div></div>
            </div>
          </div>
        </div>
      )}

      {isShareModalOpen && (
        <ShareModal onClose={() => setShareMoalOpen(false)}></ShareModal>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'>
                <div>
                  <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-greeny-300'>
                    <CheckIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg text-center leading-6 font-medium text-gray-900'
                    >
                      Well done {user?.firstName}!
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Great results come from small steps consistently worked
                        on over time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    // type='button'
                    className='inline-flex justify-center w-full rounded-md px-4 py-2 bg-greeny-500 text-base font-medium text-white hover:bg-greeny-300 sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greeny-500'
                    onClick={() => setOpen(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default WorkoutFull
