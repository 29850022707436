import { Link } from 'react-router-dom'
import AppContext from '../context/AppContext'
import { useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { isMobileNavNeeded } from '../utils/Common'

const scrollToElement = (url) => {
  try {
    const divElement = document.getElementById(`vertical_nav_${url}`)
    divElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  } catch (error) {
    // console.error(error)
  }
}

const NAVIGATIONS = [
  { url: '/', label: 'For you' },
  { url: '/browse', label: 'Browse' },
  { url: '/class-types', label: 'Class types' },
  { url: '/weekly-schedule', label: 'Weekly schedule' },
  { url: '/challenges', label: 'Programs' },
  { url: '/your-workouts', label: 'Your workouts' },
]

export default function NavBarOnlyMoble() {
  const { data } = useContext(AppContext)
  let { weekly_challenges } = data
  try {
    const readable_url = weekly_challenges[0].readable_url
    NAVIGATIONS.map((item) => {
      if (item.url === '/weekly-schedule') {
        item.url += `/${readable_url}`
      }
    })
  } catch (error) {
    console.log(error)
  }

  const location = useLocation()

  useEffect(() => {
    scrollToElement(location.pathname)
  }, [])

  return (
    <div>
      {isMobileNavNeeded(location, NAVIGATIONS) && (
        <div className='h-16 sm:hidden relative border-b border-t bg-white'>
          <div className='w-screen absolute sm:hidden space-x-8 pl-5 pr-5 flex flex-row overflow-y-hidden overflow-x-auto h-16 -my-0.5 scrolling-touch no-scrollbar'>
            {NAVIGATIONS?.map(({ url, label }) => (
              <Link
                onClick={() => {
                  setTimeout(() => scrollToElement(url), 10)
                }}
                key={`only_mobile_nav_${url}-${label}`}
                to={url}
                className={`${
                  location.pathname === url
                    ? 'border-b-2 border-gray-900 text-gray-900 font-semibold'
                    : ' break-normal'
                } text-gray-500 text-base inline-flex whitespace-nowrap text-center  items-center text font-medium`}
              >
                <span id={`vertical_nav_${url}`}>{label}</span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
