import React, { useEffect } from 'react'
import { useContext } from 'react'
import AppContext from '../context/AppContext'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const { data } = useContext(AppContext)
  const { user } = data
  const history = useHistory()

  useEffect(() => {
    // If user sign redirect to /
    if (user && user.email) {
      history.push('/')
    }
  }, [])

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 px-12 sm:px-0 sm:gap-10 bgMB max-h-screen'>
      <div className='bg-gray-50 pb-9 sm:pb-14 flex-grow flex items-center h-full justify-center'>
        <div className='my-auto'>
          <div
            data-ms-hide='auth'
            className='mx-auto p-10 flex flex-col justify-center'
          >
            <div className='flex justify-center'>
              <img
                className='block h-5 sm:h-5 w-auto'
                src='/withsara-logo.svg'
                alt='Workflow'
              />
            </div>
            <div>
              <div className='mt-20'>
                <iframe
                  src='https://members.withsara.co/member/sign_in?embedded=yes'
                  width='100%'
                  height='500'
                  seamless='true'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden md:block'>
        <img
          src='/workoutspace.png'
          className='object-cover object-center h-screen w-full'
          alt=''
        />
      </div>
    </div>
  )
}

export default Login
