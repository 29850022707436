import { useContext } from 'react'
const RODUCTION = process.env.REACT_APP_PRODUCTION
import WorkoutWithTime from '../components/WorkoutWithTime'
import AppContext from '../context/AppContext'
import NoResults from '../components/NoResults'
import MainHeadline from '../components/MainHeadline'
import { SignUpComponent } from '../components/SignUpComponent'
import { isNoActive } from '../utils/Common'

const YourWorkouts = () => {
  const { data, setData } = useContext(AppContext)
  const { workout_history, workout_favorites, workout_your_tabs } = data

  const setCurrentTab = (tab) => {
    const tabs = workout_your_tabs.map((t) => {
      t.current = false
      if (t.name === tab.name) {
        t.current = true
      }
      return t
    })
    setData({ ...data, workout_your_tabs: tabs })
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <MainHeadline
        title='Your workouts'
        desciption='See your favorite workouts and track your completed workouts'
        dynamicHeader={false}
      ></MainHeadline>
      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}
      {!isNoActive(data) && (
        <div className='container'>
          <div className='justify-center content-center mt-10'>
            <div className='max-w-full sm:max-w-screen-md sm:mx-auto'>
              <div className='flex justify-center'>
                <div className='bg-gray-100 p-0.5 rounded-md inline-block mx-auto'>
                  <nav className=' flex justify-center' aria-label='Tabs'>
                    {workout_your_tabs?.map((tab, index) => (
                      <div
                        key={`tab_name_${tab?.name}-${index}`}
                        onClick={() => {
                          setCurrentTab(tab)
                        }}
                        className={classNames(
                          tab.current
                            ? 'bg-white text-gray-900 border font-medium border-gray-300'
                            : 'bg-gray-100 text-gray-900  border border-gray-100',
                          'cursor-pointer px-3 py-1 font-medium text-sm rounded-md'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
              {workout_your_tabs &&
                workout_your_tabs.length > 0 &&
                workout_your_tabs[1].current &&
                workout_history?.length > 0 && (
                  <div className='component align-top justify-start  mt-10'>
                    {workout_history?.map((history_element, index) => (
                      <div
                        key={`workout_history_${history_element?.id}-${index}`}
                      >
                        <div className='font-semibold sm:font-semibold text-sm sm:text-base mb-2 sm:mb-5'>
                          {history_element?.date &&
                            new Date(history_element.date).toLocaleDateString(
                              'en-US',
                              {
                                weekday: 'long',
                                day: 'numeric',
                                month: 'long',
                              }
                            )}
                        </div>
                        {history_element?.workouts?.map(
                          (workout_history, index) => (
                            <div
                              key={`workout_history_${workout_history?.id}-${index}`}
                              className='w-full my-5'
                            >
                              <WorkoutWithTime
                                workoutId={workout_history.workout}
                                url={`/workout/${workout_history.workout}`}
                                done_date={workout_history.action_time}
                                text='Completed'
                              ></WorkoutWithTime>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
              {workout_your_tabs &&
                workout_your_tabs.length > 0 &&
                workout_your_tabs[1].current &&
                workout_history?.length === 0 && (
                  <NoResults
                    text='No workouts found'
                    description='Remember to mark your workout as complete.'
                  ></NoResults>
                )}
              {workout_your_tabs &&
                workout_your_tabs.length > 0 &&
                workout_your_tabs[0].current &&
                workout_favorites?.length > 0 && (
                  <div className='component align-top justify-start  mt-10'>
                    {workout_favorites?.map((history_favorite, index) => (
                      <div
                        key={`favorites_days_workouts_${history_favorite?.date}-${index}`}
                      >
                        <div className='font-semibold sm:font-semibold text-sm sm:text-base mb-2 sm:mb-5'>
                          {history_favorite?.date &&
                            new Date(history_favorite.date).toLocaleDateString(
                              'en-US',
                              {
                                weekday: 'long',
                                day: 'numeric',
                                month: 'long',
                              }
                            )}
                        </div>
                        {history_favorite?.workouts?.map(
                          (favorite_workout, index) => (
                            <div
                              key={`workout_favorites_${favorite_workout.id}-${index}`}
                              className='w-full my-5'
                            >
                              <WorkoutWithTime
                                workoutId={favorite_workout.workout.id}
                                url={`/workout/${favorite_workout.workout.id}`}
                                done_date={favorite_workout.done_date}
                                text='Completed'
                              ></WorkoutWithTime>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}

              {workout_your_tabs &&
                workout_your_tabs.length > 0 &&
                workout_your_tabs[0].current &&
                workout_favorites?.length === 0 && (
                  <NoResults
                    text='No workouts found'
                    description='You have not added any favorite workouts yet.'
                  ></NoResults>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default YourWorkouts
