import { useState } from 'react'
import { saveShareInfo } from '../api/APIUtils'
import MainHeadline from '../components/MainHeadline'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { isNoActive } from '../utils/Common'
import { CheckIcon } from '@heroicons/react/outline'
import { SignUpComponent } from '../components/SignUpComponent'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
const Share = () => {
  const [inputText, setInputText] = useState('')
  const [open, setOpen] = useState(false)
  const { data } = useContext(AppContext)

  return (
    <>
      <MainHeadline
        title='Send your friend an amazing gift'
        desciption='Invite your friends to Withsara and give them a free month. You’ll get $39 applied to your monthly plan when your friend registers with your personal code and becomes a member'
      ></MainHeadline>

      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}
      {!isNoActive(data) && (
        <div className='containerSuperSmall'>
          <div>
            <label
              htmlFor='comment'
              className='block text-base text-gray-900 font-medium'
            >
              Enter up to 5 email addresses below
            </label>
            <div className='mt-2'>
              <textarea
                rows={4}
                name='comment'
                id='comment'
                value={inputText}
                className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900'
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>

            <div className='mt-5 flex'>
              <button
                type='submit'
                onClick={() => {
                  saveShareInfo(inputText, () => {})
                  setInputText('')
                  setOpen(true)
                }}
                className='w-full inline-flex items-center py-1 h-10 justify-center mt-1 sm:mt-0 px-4 sm:px-8 text-sm font-medium rounded-md text-white bg-gray-900 focus:ring-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 truncate'
                // className='inline-flex w-full justify-center text-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-900 hover:bg-gray-500'
              >
                Send invitation
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='mt-5'>
        <script src='https://apps.elfsight.com/p/platform.js' defer></script>
        <div className='elfsight-app-3002d7c0-fd2a-4f43-b5e6-35944dd69b9c'></div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6'>
                <div>
                  <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-greeny-300'>
                    <CheckIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg text-center leading-6 font-medium text-gray-900'
                    >
                      Invites sent
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        We will now send your friends an invite to come work out
                        with you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    // type='button'
                    className='inline-flex justify-center w-full rounded-md px-4 py-2 bg-greeny-500 text-base font-medium text-white hover:bg-greeny-300 sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greeny-500'
                    onClick={() => setOpen(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Share
