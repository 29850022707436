import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { CogIcon, SparklesIcon, UserAddIcon } from '@heroicons/react/solid'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Constants from '../utils/Constants'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProfileMenu() {
  const { data } = useContext(AppContext)
  const { user } = data
  return (
    <Menu as='div' className='relative inline-block text-left h-8 w-8'>
      <div>
        <Menu.Button className='bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>
          <span className='sr-only'>Open user menu</span>
          {user.profileImageUrl && user.profileImageUrl !== null && (
            <img
              className='h-8 w-8 rounded-full object-cover object-center'
              src={`${user?.profileImageUrl}`}
              alt=''
            />
          )}
          {(user.profileImageUrl === null ||
            user.profileImageUrl === undefined) && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0.5 0.5 47 47'
              height='100%'
              className='h-8 w-8 rounded-full'
            >
              <circle
                cx='24'
                cy='24'
                r='22.5'
                fill='#fffefc'
                stroke='#CBCBCB'
                strokeWidth='2'
              ></circle>
              <circle
                cx='24'
                cy='18.3'
                r='7.7'
                fill='none'
                stroke='#CBCBCB'
                strokeWidth='2'
              ></circle>
              <path
                fill='none'
                stroke='#CBCBCB'
                strokeWidth='2'
                d='M8.5 40.1c0-7.3 4.7-13.3 11.3-15.2m8.3 0c6.7 1.8 11.5 8 11.5 15.2'
              ></path>
            </svg>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-20'>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={Constants.MB_PROFILE_LINK}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <CogIcon
                    className='mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  Account settings
                </a>
              )}
            </Menu.Item>
          </div>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/upgrade'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <SparklesIcon
                    className='mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  Upgrade plan
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to='/share'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-500',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <UserAddIcon
                    className='mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  Invite a friend
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
