import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// This class is only used to track MB changes in Login page
// Because for redirection we have to be inside the Router (not on an App level)

export default function MemberSpaceComponent() {
  const history = useHistory()

  useEffect(() => {
    console.log('Member Space starts')
    // run
    getMsMemberInfoPromise().then(({ memberInfo }) => {
      // your code here
      console.log(
        'MB:Promis--MEMBER INFO UPDATE------------------------',
        memberInfo
      )
      // if page is login
      if (window.location.pathname === '/login') {
        if (memberInfo && memberInfo.email) {
          history.push('/')
        }
      }
    })
  }, [])

  const getMsMemberInfoPromise = () =>
    new Promise((resolve) => {
      const handleReady = ({ detail }) => {
        resolve(detail)
      }
      document.addEventListener('MemberSpace.member.info', handleReady)
    })

  return null
}
