import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  CheckIcon,
  ShareIcon,
  FireIcon,
  HandIcon,
  CalendarIcon,
} from '@heroicons/react/outline'
import {
  FlagIcon,
  PlusCircleIcon,
  MusicNoteIcon,
} from '@heroicons/react/outline'

const WorkoutInfoBlock = ({
  icon,
  title,
  text,
  link,
  dictionaryId,
  list,
  tagUrl,
  share,
  onShareClicked,
}) => {
  return (
    <div>
      <div className='w-full flex flex-row justify-start items-start py-5'>
        <div className='mr-5 pt-2 sm:pt-0.5'>
          {icon === 'flag' && <FlagIcon className='w-5 h-5 text-gray-300' />}
          {icon === 'share' && <ShareIcon className='w-5 h-5 text-gray-300' />}
          {icon === 'calendar' && (
            <CalendarIcon className='w-5 h-5 text-gray-300' />
          )}
          {icon === 'plus' && (
            <PlusCircleIcon className='w-5 h-5 text-gray-300' />
          )}
          {icon === 'music' && (
            <MusicNoteIcon className='w-5 h-5 text-gray-300' />
          )}
          {icon === 'fire' && <FireIcon className='w-5 h-5 text-gray-300' />}
          {icon === 'hand' && <HandIcon className='w-5 h-5 text-gray-300' />}
        </div>
        <div className='flex flex-col sm:flex-row sm:w-full'>
          <div className='truncate text-sm sm:text-base font-semibold flex-1 text-gray-900'>
            {title}
          </div>

          {list && list.length > 0 && (
            <div className='text-center text-sm sm:text-base text-gray-500 space-x-1 flex truncate hover:text-gray-900 hover:underline '>
              {list?.map((item, index) => (
                <Link
                  key={`workout_info_${item?.id}-${index}`}
                  to={`/browse?filter=${tagUrl}:${item?.id}`}
                >
                  {item?.name}
                  {index != list.length - 1 && <span>,</span>}
                </Link>
              ))}
            </div>
          )}
          {text !== '' && (
            <Link to={`/browse?filter=${tagUrl}:${dictionaryId}`}>
              <div className='text-center text-sm sm:text-base text-gray-500 space-x-1 flex truncate hover:text-gray-900 hover:underline '>
                {text}
              </div>
            </Link>
          )}
          {link !== '' && (
            <div className='text-center truncate text-gray-500 hover:underline'>
              <a
                href={link}
                className='text-blue-400 font-medium text-sm sm:text-base'
                target='_blank'
                rel='noreferrer'
              >
                View playlist in Spotify
              </a>
            </div>
          )}
          {share && (
            <div className='text-center cursor-pointer truncate text-gray-500 hover:underline'>
              <a
                onClick={onShareClicked}
                className='text-blue-400 font-medium text-sm sm:text-base'
                target='_blank'
                rel='noreferrer'
              >
                Share this workout with a friend
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
WorkoutInfoBlock.defaultProps = {
  icon: CheckIcon,
  title: '',
  text: '',
  link: '',
  dictionaryId: 0,
  tagUrl: '',
  list: [],
  share: false,
  onShareClicked: () => {},
}

WorkoutInfoBlock.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  link: PropTypes.string,
  dictionaryId: PropTypes.number,
  tagUrl: PropTypes.string,
  list: PropTypes.array,
  share: PropTypes.bool,
  onShareClicked: PropTypes.func,
}

export default WorkoutInfoBlock
