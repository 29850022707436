import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
const BASE_URL = process.env.REACT_APP_API_URL
const WorkoutType = ({ workout_type }) => {
  return (
    <div className='rounded-xl sm:rounded-lg relative'>
      <Link to={`/browse?filter=workout_types:${workout_type?.id}`}>
        <div className='space-y-2 transform transition-transform hover:opacity-75'>
          <div className='aspect-w-12 aspect-h-12'>
            {workout_type?.image?.formats?.small?.url ? (
              <img
                src={`${BASE_URL}${workout_type?.image?.formats?.small?.url}`}
                className='w-full h-full object-fill object-center lg:w-full lg:h-full rounded '
              />
            ) : (
              <img
                src={`${BASE_URL}${workout_type?.image?.url}`}
                className='w-full h-full object-fill object-center lg:w-full lg:h-full rounded '
              />
            )}
          </div>
          <div className='font-medium space-y-1 flex justify-center'>
            <div className='h3 text-sm sm:text-base inline-block font-semibold sm:font-bold  text-center'>
              {workout_type?.name}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

WorkoutType.defaultProps = {
  workout_type: {},
}
WorkoutType.propTypes = {
  workout_type: PropTypes.object.isRequired,
}

export default WorkoutType
