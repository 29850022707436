import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Carousel from '../components/Carousel'
import Constants from '../utils/Constants'

const LINK_TO_FULL_INFO = 'weekly-schedule'
const WeeklySchedule = () => {
  const { data } = useContext(AppContext)
  let prefix = ''
  let readable_url = ''
  let weekly_challenges_workouts = []
  let weekly_challenges = []

  try {
    weekly_challenges_workouts = data.weekly_challenges_workouts
    weekly_challenges = data.weekly_challenges
    readable_url = weekly_challenges[0].readable_url
    prefix = `/weekly-schedule/${readable_url}`
  } catch (error) {
    console.log('White screen error cause')
    console.log(error)
  }
  return (
    <div>
      <Carousel
        prefix={prefix}
        name='Weekly schedule'
        link={`${LINK_TO_FULL_INFO}/${readable_url}`}
        itemsCount={Constants.CAROUSEL_ITEMS.weekly}
        data={weekly_challenges_workouts}
        type='workout'
        breakPoints={Constants.BREAKPOINT_WORKOUTS}
      />
    </div>
  )
}

export default WeeklySchedule
