import AppContext from '../context/AppContext'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import WeeklyChallenge from './WeeklyChallenge'
import MainHeadline from '../components/MainHeadline'
import { setStateChallegeJoinStatus } from '../api/StateUtils'
import { isJoinedToChallnge } from '../utils/Common'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { CheckCircleIcon as CheckCircleIconOuline } from '@heroicons/react/outline'
import MonthlyNavigation from '../components/MonthlyNavigation'
import { SignUpComponent } from '../components/SignUpComponent'
import moment from 'moment'
// import { SignUpModal } from '../components/SignUpModal'
import { isNoActive } from '../utils/Common'

const Challenge = () => {
  let { id } = useParams()

  const { data, setData } = useContext(AppContext)

  const {
    monthly_challenges,
    monthly_challenge,
    challenges_current_week,
    user,
  } = data

  let isUpdateWeek = true

  const getWeekNumber = (monthly_challenge) => {
    if (monthly_challenge) {
      if (monthly_challenge.ongoing_badge) {
        const startDate = new Date(monthly_challenge.start_date)
        const endDate = new Date()
        return moment(endDate).diff(startDate, 'weeks')
      }
      return 0
    }
    return 0
  }

  useEffect(() => {
    if (monthly_challenges && monthly_challenges.length > 0) {
      const monthly_challenge = monthly_challenges.find(
        (monthly_challenge) =>
          monthly_challenge.readable_url.toString() === id.toString()
      )
      data.monthly_challenge = monthly_challenge

      data.max_weeks = monthly_challenge.weekly_challenges.length
      // data.monthly_text = { name: `Week 1 of ${data.max_weeks}` }

      if (
        challenges_current_week &&
        monthly_challenge &&
        monthly_challenge.id &&
        data &&
        monthly_challenge.id === data.challenges_current_monthly_challenge.id
      ) {
        isUpdateWeek = false
      }

      if (monthly_challenge && isUpdateWeek) {
        data.challenges_current_monthly_challenge = { ...monthly_challenge }
        data.challenges_current_starting_day = new Date(
          monthly_challenge.start_date
        )
        data.challenges_current_starting_week_day =
          data.challenges_current_starting_day
      }

      // Keep pointer on a right week
      if (monthly_challenge && isUpdateWeek) {
        const weekly_challenges = monthly_challenge.weekly_challenges

        // HERE we add check on working version,
        // So for safety reason we add ugly try/catch inside
        let current_week = 0

        try {
          current_week = getWeekNumber(monthly_challenge)
          if (current_week > weekly_challenges.length) {
            current_week = weekly_challenges.length - 1
          }
        } catch (error) {
          console.log('Error with find exact week')
        }

        const weekly_challenge = weekly_challenges[current_week] // Here we can place calculated week
        // const weekly_challenge = weekly_challenges[0] // Here we can place calculated week
        if (weekly_challenge && weekly_challenge.challenges) {
          data.challenges_current_week = { ...weekly_challenge }
          data.challenges_current_day = { ...weekly_challenge.challenges[0] }
        }
        setWeek(weekly_challenge, current_week)
      }
      setData({ ...data })
    }
  }, [monthly_challenges, monthly_challenges[0].trigger])

  const setWeek = (week, weekNumber) => {
    data.challenges_current_week = { ...week }
    data.challenges_current_day = { ...week.challenges[0] }
    data.challenges_current_starting_week_day = new Date(
      data.challenges_current_starting_day.getFullYear(),
      data.challenges_current_starting_day.getMonth(),
      data.challenges_current_starting_day.getDate() + weekNumber * 7
    )
    data.monthly_text = { name: `Week ${weekNumber + 1} of ${data.max_weeks}` }

    setData({ ...data })
  }

  return (
    <div>
      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}
      {!isNoActive(data) && (
        <div className='container text-right pt-5'>
          {isJoinedToChallnge(user, monthly_challenge) ? (
            <button
              onClick={() =>
                setStateChallegeJoinStatus(
                  user,
                  monthly_challenge,
                  data,
                  setData
                )
              }
              className='inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-white bg-greeny-500 hover:bg-greeny-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greeny-500'
            >
              <CheckCircleIcon
                className='-ml-0.5 mr-2 h-4 w-4'
                aria-hidden='true'
              />
              You joined challenge
            </button>
          ) : (
            <button
              onClick={() =>
                setStateChallegeJoinStatus(
                  user,
                  monthly_challenge,
                  data,
                  setData
                )
              }
              className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
            >
              <CheckCircleIconOuline
                className='-ml-0.5 mr-2 h-4 w-4'
                aria-hidden='true'
              />
              Join challenge
            </button>
          )}
        </div>
      )}

      {!isNoActive(data) && monthly_challenge && (
        <div className='-mt-1 sm:-mt-20'>
          <MainHeadline
            title={monthly_challenge?.name}
            desciption={monthly_challenge?.short_description}
            fullDescription={monthly_challenge?.description}
            ongoingBadge={!!monthly_challenge?.ongoing_badge}
            containerClass='containerSmall'
            extendable={true}
          ></MainHeadline>
        </div>
      )}
      {!isNoActive(data) && (
        <div className=''>
          {monthly_challenge && (
            <MonthlyNavigation
              setWeek={setWeek}
              monthly_challenge={monthly_challenge}
              challenges_current_week={challenges_current_week}
            />
          )}
          <div>
            {monthly_challenge && data.challenges_current_starting_week_day && (
              <WeeklyChallenge
                type='challenge'
                prefix={`/challenges/${id}/w${data?.challenges_current_week?.id}`}
                isOngoing={!!monthly_challenge?.ongoing_badge}
                today={data.challenges_current_starting_week_day}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Challenge
