import React from 'react'
const AppContext = React.createContext({
  debug: false,
  userMetaInfo: [],
  subsciptionType: 'noActive',
  location: '/',
  user: {},
  news: [],
  banner: {},
  topLine: {},
  topLineClosed: true,
  expendedMainHeader: false,

  // Source of truth
  workouts: [],

  // Challenges
  weekly_challenges: [],
  weekly_challenges_workouts: [],
  monthly_challenges: [],

  // Browse (for filtering)
  workoutsFiltered: [],
  workoutsFilteredPaginated: [],

  // For Most Popular Panel
  workoutsMostPopular: [],

  // User history
  workout_your_tabs: [],
  workout_history: [],
  workout_favorites: [],

  // Dictinaries
  teachers: [],
  workout_intensities: [],
  workout_types: [],
  workout_levels: [],
  workout_equipment: [],
  workout_body_parts: [],
  wourkout_teachers: [],
  wourkout_goals: [],

  // Current
  monthly_challenge: {},
  max_weeks: 0,
  monthly_text: '',

  challenges_current_monthly_challenge: {},
  challenges_current_days: {},
  challenges_current_starting_week_day: new Date(),
  challenges_current_starting_day: new Date(),

  // Week object for week insidet the challenge
  challenges_current_week: {},
  // Week object for weekly schedule
  weekly_current_schedule: {},

  // filters
  filters: [],
  activeFilters: [],
  activeFiltersMap: {},
  sortOptions: [],

  // pagination
  page: 1,
  selectedChallenge: -1,
  newMessage: false,
  selectedPlanIndex: 0,
  setData: () => {},
})
export const AppProvider = AppContext.Provider
export default AppContext
