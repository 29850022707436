import React from 'react'
import PropTypes from 'prop-types'
const BASE_URL = process.env.REACT_APP_API_URL

const PromotialBanner = ({ text, link, image }) => {
  return (
    <div>
      <div className='relative my-8 sm:my-12'>
        <div className='container'>
          {/* <div className='max-w-8xl mx-auto'> */}
          <a href={link} className='w-full h-full cursor-pointer block'>
            <img
              className='h-full w-full object-contain'
              src={`${BASE_URL}${image?.url}`}
              alt='People working on laptops'
            />
          </a>
        </div>
      </div>
    </div>
  )
}
PromotialBanner.defaultProps = {
  text: '',
  link: '',
  image: {},
}

PromotialBanner.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default PromotialBanner
