import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Carousel from '../components/Carousel'
import Constants from '../utils/Constants'

const ClassTypes = () => {
  const { data } = useContext(AppContext)
  let { workout_types } = data
  return (
    <div>
      <Carousel
        name='Class categories'
        link={Constants.CAROUSEL_LINKS.types}
        itemsCount={Constants.CAROUSEL_ITEMS.types}
        data={workout_types}
        type='classTypes'
        customClassName='classTypes'
        breakPoints={Constants.BREAKPOINT_TYPES}
      />
    </div>
  )
}

export default ClassTypes
