import React, { useEffect, useState } from 'react'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from '@heroicons/react/outline'
import { RadioGroup } from '@headlessui/react'
import CurrencyDropDown from '../components/CurrencyDropDown'
const transferFeatures = [
  {
    id: 1,
    name: '​​Commit to your journey',
    description: 'Make it easier to stick to your routine',
  },
  {
    id: 2,
    name: 'Save money',
    description: 'Save $229 a year on an annual plan',
  },
  {
    id: 3,
    name: 'Use your workout allowance',
    description: 'Many workplaces refund the entire Withsara membership',
  },
]

const plans = [
  {
    currency: 'USD',
    publicPlanId: 'vfngpo0vuf',
    name: 'Annually',
    text: 'Save 50% - Billed at $229 a year',
    price: '$19',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: true,
    href: 'https://members.withsara.co/member/plans/vfngpo0vuf',
  },
  {
    currency: 'USD',
    publicPlanId: 'b548fa1dfp',
    name: 'Bi Annually',
    text: 'Save 33% - Billed at $179 twice a year',
    price: '$29',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: false,
    href: 'https://members.withsara.co/member/plans/b548fa1dfp',
  },
  {
    currency: 'USD',
    publicPlanId: '6a1c76a13a',
    name: 'Monthly',
    text: 'Billed at $39 a month',
    price: '$39',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: false,
    href: 'https://members.withsara.co/member/plans/6a1c76a13a',
  },
  // SEK
  {
    currency: 'SEK',
    publicPlanId: '52b7945adp',
    name: 'Annually',
    text: 'Save 50% - Billed at SEK2299 a year',
    price: 'SEK192',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: true,
    href: 'https://members.withsara.co/member/plans/52b7945adp',
  },
  {
    currency: 'SEK',
    publicPlanId: 'bff940e19p',
    name: 'Bi Annually',
    text: 'Save 33% - Billed at SEK1750 twice a year',
    price: 'SEK292',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: false,
    href: 'https://members.withsara.co/member/plans/bff940e19p',
  },
  {
    currency: 'SEK',
    publicPlanId: '8b48107e0e',
    name: 'Monthly',
    text: 'Billed at SEK399 a month',
    price: 'SEK399',
    per: 'month',
    buttonText: 'Select',
    isActive: false,
    mostPopular: false,
    href: 'https://members.withsara.co/member/plans/8b48107e0e',
  },
  // {
  //   currency: 'SEK',
  //   publicPlanId: '7b4a5d90dp',
  //   name: 'DEV testing plan',
  //   text: 'Save 33% - Billed SEK*** a year',
  //   price: 'SEK399',
  //   per: 'month',
  //   buttonText: 'Select',
  //   isActive: false,
  //   mostPopular: true,
  // },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Upgrade = (props) => {
  const [selected, setSelected] = useState(plans[0])
  const [noPlan, setNoPlan] = useState(false)
  const [currency, setCurrency] = useState('USD')
  const { data, setData } = useContext(AppContext)
  const { user } = data

  useEffect(() => {
    setData({ ...data, ...{ mobileNavVisible: false } })
    if (user && user.memberships && user.memberships.length > 0) {
      setNoPlan(true)
      let publicPlanId = user.memberships[0].publicPlanId
      let planStatus = user.memberships[0].publicPlanId
      user.memberships.map((membershipPlan) => {
        if (
          membershipPlan.status === 'active' ||
          membershipPlan.status === 'trialing'
        ) {
          publicPlanId = membershipPlan.publicPlanId
          planStatus = 'active'
        }
      })

      plans.map((plan) => {
        if (
          publicPlanId &&
          planStatus === 'active' &&
          plan.publicPlanId.toString() === publicPlanId.toString()
        ) {
          setCurrency(plan.currency)
          setNoPlan(false)
          setSelected(plan)
        }
      })
    } else {
      setNoPlan(true)
    }
  }, [])

  return (
    <div>
      <div className='container'>
        <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center'>
          <div className='relative'>
            <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight lg:text-3xl'>
              Why upgrade?
            </h3>
            <p className='mt-3 text-base text-gray-500'>
              Save money and commit to your health, all at the same time.
            </p>

            <dl className='mt-10 space-y-5 hidden lg:block'>
              {transferFeatures.map((item, index) => (
                <div
                  key={`upgrade_feature_${item?.id}-${index}`}
                  className='relative'
                >
                  <dt>
                    <div className='absolute flex items-center justify-center h-7 w-7 rounded-md textGreen'>
                      <CheckIcon className='h-7 w-7' aria-hidden='true' />
                    </div>
                    <p className='ml-10 text-lg leading-6 font-semibold text-gray-900'>
                      {item.name}
                    </p>
                  </dt>
                  <dd className='mt-2 ml-10 text-base text-gray-500'>
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div>
            <div className='flex justify-between items-center'>
              <div className='my-5 font-bold'>Choose your plan</div>
              <CurrencyDropDown
                currency={currency}
                setCurrency={(currency) => {
                  setCurrency(currency)
                }}
              ></CurrencyDropDown>
            </div>
            <div
              value={selected}
              onChange={() => {
                setSelected
              }}
            >
              <div className='sr-only'>Withsara plans</div>
              <div className='space-y-4'>
                {plans
                  .filter((plan) => plan.currency === currency)
                  .map((plan, index) => (
                    //  {currency == plan.currency &&
                    <a
                      key={`upgrade_plan_name_${plan?.name}-${index}`}
                      value={plan}
                      href={
                        selected.publicPlanId.toString() ===
                          plan.publicPlanId.toString() && noPlan === false
                          ? ''
                          : plan.href
                      }
                      className={classNames(
                        selected.publicPlanId.toString() ===
                          plan.publicPlanId.toString() && noPlan === false
                          ? 'ring-2 ring-greeny-500'
                          : '',
                        'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none  justify-evenly'
                      )}
                    >
                      {/* <div className=''> */}
                      <div className='flex items-center flex-1'>
                        <div className='text-sm flex flex-row justify-between w-full items-center'>
                          <div className='flex-1'>
                            <p className='font-medium text-gray-900'>
                              {plan.name}
                              {plan.mostPopular === true && (
                                <span className='bg-greeny-500 rounded text-white text-xs px-1 ml-2 py-0.5'>
                                  {' '}
                                  MOST POPULAR
                                </span>
                              )}
                            </p>
                            <div className='text-gray-500 mt-0.5'>
                              <p className='sm:inline'>{plan.text}</p>
                            </div>
                          </div>
                          <div>
                            <div className='mt-1 flex text-sm sm:hidden sm:ml-4 sm:text-right'>
                              {selected.publicPlanId.toString() ===
                                plan.publicPlanId.toString() &&
                                noPlan === false && (
                                  <div className='font-bold text-gray-900 mt-2'>
                                    Current plan
                                  </div>
                                )}
                              {(noPlan === true ||
                                selected.publicPlanId.toString() !==
                                  plan.publicPlanId.toString()) && (
                                <button
                                  onChange={setSelected}
                                  // type='button'
                                  className='inline-flex items-center px-3 py-2 border border-transparent border-greeny-500 text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-greeny-500 hover:bg-greeny-300 hover:border-greeny-300'
                                >
                                  {plan.buttonText}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3 flex text-sm sm:block sm:ml-4 sm:text-right'>
                        <div className='font-bold text-gray-900'>
                          {plan.price}{' '}
                          <span className='font-medium text-gray-500'>
                            /{plan.per}
                          </span>
                        </div>
                      </div>
                      <div className='mt-1 flex text-sm hidden sm:block sm:ml-4 sm:text-right'>
                        {selected.publicPlanId.toString() ===
                          plan.publicPlanId.toString() &&
                          noPlan === false && (
                            <div className='font-bold text-gray-900 mt-2'>
                              Current plan
                            </div>
                          )}
                        {(noPlan === true ||
                          selected.publicPlanId.toString() !==
                            plan.publicPlanId.toString()) && (
                          <button
                            onChange={setSelected}
                            className='inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-greeny-500 hover:bg-gray-900'
                          >
                            {plan.buttonText}
                          </button>
                        )}
                      </div>
                      <div
                        // className={classNames(
                        //   active ? 'border' : 'border-2',
                        //   checked
                        //     ? 'border-greeny-500'
                        //     : 'border-transparent',
                        //   'absolute -inset-px rounded-lg pointer-events-none'
                        // )}
                        aria-hidden='true'
                      />
                      {/* </div> */}
                    </a>
                    // <RadioGroup.Option
                    //   key={`upgrade_plan_name_${plan?.name}-${index}`}
                    //   value={plan}
                    //   className={({ checked, active }) =>
                    //     classNames(
                    //       checked ? 'border-transparent' : 'border-gray-300',
                    //       active ? 'ring-2 ring-greeny-500' : '',
                    //       'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none  justify-evenly'
                    //     )
                    //   }
                    // >
                    //   {({ active, checked }) => (
                    //     <>
                    //       <div className='flex items-center flex-1'>
                    //         <div className='text-sm flex flex-row justify-between w-full items-center'>
                    //           <div className='flex-1'>
                    //             <RadioGroup.Label
                    //               as='p'
                    //               className='font-medium text-gray-900'
                    //             >
                    //               {plan.name}
                    //               {plan.mostPopular === true && (
                    //                 <span className='bg-greeny-500 rounded text-white text-xs px-1 ml-2 py-0.5'>
                    //                   {' '}
                    //                   MOST POPULAR
                    //                 </span>
                    //               )}
                    //             </RadioGroup.Label>
                    //             <RadioGroup.Description
                    //               as='div'
                    //               className='text-gray-500 mt-0.5'
                    //             >
                    //               <p className='sm:inline'>{plan.text}</p>
                    //             </RadioGroup.Description>
                    //           </div>
                    //           <div>
                    //             <RadioGroup.Description
                    //               as='div'
                    //               className='mt-1 flex text-sm sm:hidden sm:ml-4 sm:text-right'
                    //             >
                    //               {plan.isActive && (
                    //                 <div className='font-bold text-gray-900 mt-2'>
                    //                   Current plan
                    //                 </div>
                    //               )}
                    //               {!plan.isActive && (
                    //                 <button
                    //                   onChange={setSelected}
                    //                   // type='button'
                    //                   className='inline-flex items-center px-3 py-2 border border-transparent border-greeny-500 text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-greeny-500 hover:bg-greeny-300 hover:border-greeny-300'
                    //                 >
                    //                   {plan.buttonText}
                    //                 </button>
                    //               )}
                    //             </RadioGroup.Description>
                    //           </div>
                    //         </div>
                    //       </div>
                    //       <RadioGroup.Description
                    //         as='div'
                    //         className='mt-3 flex text-sm sm:block sm:ml-4 sm:text-right'
                    //       >
                    //         <div className='font-bold text-gray-900'>
                    //           {plan.price}{' '}
                    //           <span className='font-medium text-gray-500'>
                    //             /{plan.per}
                    //           </span>
                    //         </div>
                    //       </RadioGroup.Description>
                    //       <RadioGroup.Description
                    //         as='div'
                    //         className='mt-1 flex text-sm hidden sm:block sm:ml-4 sm:text-right'
                    //       >
                    //         {selected.publicPlanId.toString() ===
                    //           plan.publicPlanId.toString() && (
                    //           <div className='font-bold text-gray-900 mt-2'>
                    //             Current plan
                    //           </div>
                    //         )}
                    //         {selected.publicPlanId.toString() !==
                    //           plan.publicPlanId.toString() && (
                    //           <button
                    //             onChange={setSelected}
                    //             className='inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-greeny-500 hover:bg-gray-900'
                    //           >
                    //             {plan.buttonText}
                    //           </button>
                    //         )}
                    //       </RadioGroup.Description>
                    //       <div
                    //         className={classNames(
                    //           active ? 'border' : 'border-2',
                    //           checked
                    //             ? 'border-greeny-500'
                    //             : 'border-transparent',
                    //           'absolute -inset-px rounded-lg pointer-events-none'
                    //         )}
                    //         aria-hidden='true'
                    //       />
                    //     </>
                    //   )}
                    // </RadioGroup.Option>
                  ))}
                <div className='text-gray-300 font-medium text-tiny'>
                  {`By clicking "Select", you agree to our `}
                  <a
                    className='underline'
                    href='https://www.withsara.co/terms'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms of Service
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

{
  /* Upgrade.propTypes = {} */
}

export default Upgrade
