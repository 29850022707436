import { useContext } from 'react'
import AppContext from '../context/AppContext'
import PropTypes from 'prop-types'
import React from 'react'
import { isWeekDayCompleted } from '../utils/Common'
import { CheckIcon } from '@heroicons/react/outline'

const WeekNavigationSimple = ({ week, onDayClick, selectedIndex, prefix }) => {
  const { data } = useContext(AppContext)
  return (
    <div className='content-center mb-4'>
      <div className='hidden sm:flex flex-wrap items-center space-x-7 self-center justify-center'>
        {week.map((day, index) => (
          <div key={`week_simple_index_${index}-${index}`}>
            <div
              onClick={(event) => onDayClick(event, index)}
              className={`${
                selectedIndex === index ? 'border-2 border-gray-900' : 'border'
              } relative cursor-pointer inline-block sm:flex sm:flex-col items-center px-8 py-2 w-28 my-2 border-gray-300 text-sm font-medium rounded-lg text-gray-500 bg-white hover:bg-gray-100`}
            >
              {isWeekDayCompleted(
                `${prefix}/c${day.weeklyChallengeId}`,
                data
              ) && (
                <div className='absolute right-0 justify-end mr-2.5 -mt-1 h-3.5 w-3.5'>
                  <CheckIcon className='sm:mt-1.5 h-3.5 w-3.5 bg-greeny-500 rounded-full text-white'></CheckIcon>
                </div>
              )}
              <div className='text-gray-500 text-base font-medium pt-1.5'>
                Day
              </div>
              <div className='text-4xl font-medium text-gray-900 -mt-1.5'>
                {index + 1}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='sm:hidden space-x-5 flex flex-row overflow-y-hidden overflow-x-auto h-15 scrolling-touch no-scrollbar border-t border-b border-gray-200 py-3 px-5'>
        {week.map((day, index) => (
          <div key={`day_week_simple_${day}-${index}`}>
            <div
              id={`week_nav_${index}`}
              onClick={(event) => {
                onDayClick(event, index)
              }}
              className={`${
                selectedIndex === index ? 'border-gray-500' : ''
              }  relative cursor-pointer flex items-center px-3 py-1 my-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-500 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 `}
            >
              {isWeekDayCompleted(
                `${prefix}/c${day.weeklyChallengeId}`,
                data
              ) && (
                <div className='absolute right-0 justify-end mr-1 -mt-7 '>
                  <CheckIcon className='w-1.5 mt-1.5 bg-greeny-500 rounded-full text-white'></CheckIcon>
                </div>
              )}
              <div className='text-gray-500 text-base'>Day</div>
              <div className='ml-1 text-base font-bold text-gray-900'>
                {index + 1}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

WeekNavigationSimple.defaultProps = {
  onDayClick: () => {},
  selectedIndex: -1,
  autoDaySelection: false,
  prefix: '',
  week: [],
}
WeekNavigationSimple.propTypes = {
  onDayClick: PropTypes.func,
  selectedIndex: PropTypes.number,
  prefix: PropTypes.string.isRequired,
  week: PropTypes.array.isRequired,
}

export default WeekNavigationSimple
