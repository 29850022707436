import React from 'react'
import PropTypes from 'prop-types'
import { InformationCircleIcon } from '@heroicons/react/outline'

const NoResults = ({ text, description }) => {
  return (
    <div className='flex justify-center items-center m-12'>
      <div className='flex flex-col justify-center items-center'>
        <InformationCircleIcon className='w-9 h-9 text-gray-500'></InformationCircleIcon>
        <div className='text-base font-medium text-gray-900 mt-4'>{text}</div>
        <p className='text-gray-500 text-base font-medium text-center'>
          {description}
        </p>
      </div>
    </div>
  )
}

NoResults.defaultProps = {
  text: '',
  description: '',
}
NoResults.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default NoResults
