import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ChevronRightIcon } from '@heroicons/react/solid'

export default function PanelTopNavigation({ link, name }) {
  return (
    <div className='pb-1 pt-1 sm:pt-5 sm:pb-5 space-y-5 md:flex-1 md:min-w-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0'>
      <Link to={link} className='flex flex-shrink justify-middle align-middle'>
        <div className='h2 mt-0.5 sm:mt-0 truncate text-bae sm:text-xl font-semibold'>
          {name}
        </div>
        <div className='md:hidden'>
          <ChevronRightIcon className='w-4 h-4 sm:h-5 sm:w-5 mt-0 sm:mt-2.5 ml-1 inline-block'></ChevronRightIcon>
        </div>
      </Link>
      <div className='ml-4 pl-4 sm:ml-6 sm:pl-6 text-gray-500 hidden md:block'>
        <Link
          to={link}
          className='flex flex-shrink justify-middle align-middle'
        >
          <div className='text-gray-500 font-medium text-sm'>View more</div>
          <div>
            <ChevronRightIcon className='-mt-2 ml-1 w-3.5 h-3.5 inline-block text-gray-400'></ChevronRightIcon>
          </div>
        </Link>
      </div>
    </div>
  )
}

PanelTopNavigation.defaultProps = {
  link: '/',
  name: '',
}
PanelTopNavigation.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
