import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CheckIcon, HeartIcon } from '@heroicons/react/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import WorkoutBadges from '../components/WorkoutBadges'
import { setStateWorkoutFavoriteStatus } from '../api/StateUtils'
import {
  isWorkoutFavorite,
  isWorkoutCompleted,
  isPublishedLessThan30Days,
} from '../utils/Common'
const BASE_URL = process.env.REACT_APP_API_URL
const RODUCTION = process.env.REACT_APP_PRODUCTION

const Workout = ({ prefix, workoutId, isNewBadge, isFavoriteBadge }) => {
  const { data, setData } = useContext(AppContext)
  const { user, workouts } = data
  const source_name = prefix ? prefix : 'root'

  const workout = workouts.find((w) => w.id === workoutId)

  return (
    <div className='py-1 transform transition-transform'>
      <Link to={`${prefix}/workout/${workoutId}`} className='space-y-3 group'>
        <div className='relative'>
          <div className='hidden sm:block w-full absolute h-1/4 z-10'>
            {isNewBadge && isPublishedLessThan30Days(workout?.published_at) && (
              <span className='absolute mt-2 font-semibold ml-2 sm:ml-2.5 sm:mt-2.5  px-3 py-0.5 rounded-full text-xs bg-white text-gray-900'>
                New
              </span>
            )}
            {isFavoriteBadge && (
              <span
                data-ms-show={RODUCTION === 'true' ? 'paid' : null}
                onClick={(e) => {
                  console.log(e)
                  e.preventDefault()
                  e.stopPropagation()
                  setStateWorkoutFavoriteStatus(user, workout, data, setData)
                }}
                className='absolute transition-opacity duration-400  opacity-0 group-hover:opacity-100 z-1 mt-2 mr-2 sm:mt-2.5 sm:mr-2.5 shadow-sm bold align-top right-0  items-center p-0.5 sm:p-2 rounded-full bg-gray-100 text-gray-900  '
              >
                {!isWorkoutFavorite(user, workout) ? (
                  <div className='transition-transform duration-400 transform hover:scale-110'>
                    <HeartIcon className='roundIcon'></HeartIcon>
                  </div>
                ) : (
                  <div className='transition-transform duration-400'>
                    <HeartIconSolid className='roundIcon'></HeartIconSolid>
                  </div>
                )}
              </span>
            )}
          </div>
          <div className='aspect-w-16 aspect-h-9 group-hover:opacity-75'>
            {workout?.image?.formats?.small?.url ? (
              <img
                src={`${BASE_URL}${workout?.image?.formats?.small?.url}`}
                className='w-full h-full object-center object-cover rounded'
              />
            ) : (
              <img
                src={`${BASE_URL}${workout?.image?.url}`}
                className='w-full h-full object-center object-cover rounded'
              />
            )}
          </div>
        </div>
        <div>
          <div className='space-x-1 sm:space-x-3 flex'>
            {isWorkoutCompleted(workout, data, source_name) && (
              <div className='h-2 w-2 sm:h-3 sm:w-3 content-center align-top items-center'>
                <CheckIcon className='w-2 mt-2.5 sm:mt-2.5 sm:w-3 bg-greeny-500 rounded-full text-white'></CheckIcon>
              </div>
            )}
            <h3 className='text-sm text-gray-900 sm:text-base mt-1 inline-block font-semibold sm:font-bold truncate '>
              {workout?.name}
            </h3>
          </div>
          <WorkoutBadges workout={workout} />
        </div>
      </Link>
    </div>
  )
}

Workout.defaultProps = {
  workoutId: 1,
  isNewBadge: false,
  isFavoriteBadge: true,
  prefix: '',
}
Workout.propTypes = {
  workoutId: PropTypes.number.isRequired,
  isNewBadge: PropTypes.bool,
  isFavoriteBadge: PropTypes.bool,
  prefix: PropTypes.string,
}

export default Workout
