import { Link } from 'react-router-dom'
import {
  MenuIcon,
  MenuAlt2Icon,
  XIcon,
  HeartIcon,
} from '@heroicons/react/outline'
import AppContext from '../context/AppContext'
import { useLocation } from 'react-router-dom'
import Notifications from './Notifcations'
import { useContext, useEffect, useState } from 'react'
import ProfileMenu from '../components/ProfileMenu'
import { Disclosure } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import { isNavNeeded, markNotifcationAsRead, notPage } from '../utils/Common'

const NAVIGATIONS = [
  { url: '/', label: 'For you' },
  { url: '/browse', label: 'Browse classes' },
  { url: '/class-types', label: 'Class types' },
  { url: '/weekly-schedule', label: 'Weekly schedule' },
  { url: '/challenges', label: 'Programs' },
  { url: '/your-workouts', label: 'Your workouts' },
]

const scrollToElement = (url) => {
  try {
    const divElement = document.getElementById(`vertical_nav_${url}`)
    divElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  } catch (error) {
    console.log(error)
  }
}
export default function NavBar() {
  const { data, setData } = useContext(AppContext)

  let { news } = data

  const { user, debug } = data
  let { weekly_challenges, newMessage } = data
  try {
    const readable_url = weekly_challenges[0].readable_url

    NAVIGATIONS.map((item) => {
      if (item.url === '/weekly-schedule') {
        item.url += `/${readable_url}`
      }
    })
  } catch (error) {
    console.log(error)
  }

  useEffect(() => {
    scrollToElement(location.pathname)
  }, [])

  const location = useLocation()
  return (
    <div>
      {isNavNeeded(location) && (
        <Disclosure as='nav' className='bg-white shadow'>
          {({ open }) => (
            <>
              <div className='container text-tiny'>
                <div className='flex h-16 justify-between items-center'>
                  <Disclosure.Button className='inline-flex lg:hidden items-center justify-center h-8 px-2 -ml-2 rounded-md text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuAlt2Icon
                        className='block h-6 w-6'
                        aria-hidden='true'
                      />
                    )}
                  </Disclosure.Button>
                  <div className='flex w-full justify-start pl-2 lg:pl-0'>
                    <div className='flex-shrink-0 flex items-center'>
                      <Link to='/'>
                        <img
                          className='block lg:h-4 xl:h-5 h-3.5 w-auto'
                          src='/withsara-logo.svg'
                          alt='Workflow'
                        />
                      </Link>
                    </div>

                    {/* DESCTOP */}
                    <div className='hidden lg:flex w-full justify-center'>
                      <div className='lg:flex sm:space-x-3 h-10 my-auto'>
                        {!notPage(['/upgrade'], location) &&
                          NAVIGATIONS?.map(({ url, label }) => (
                            <Link
                              key={`desctop_nav_${url}-${label}`}
                              to={url}
                              className={`${
                                location.pathname === url
                                  ? 'bg-gray-100 text-gray-900 font-bold'
                                  : 'text-gray-500 hover:bg-gray-50 hover:text-gray-900'
                              } text-tiny rounded-md py-2 px-3 inline-flex items-center  font-medium truncate`}
                            >
                              {label}
                            </Link>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className='hidden lg:flex sm:items-center space-x-3'>
                    {/* <MenuIcon
                    className={`sm:block h-6 w-6 -rotate-90 transform  cursor-pointer ${
                      debug ? 'text-red-400' : 'text-gray-900'
                    }`}
                    aria-hidden='true'
                    onClick={() => setData({ ...data, debug: !debug })}
                  /> */}
                    <Link
                      to='/your-workouts'
                      className='p-1 rounded-full text-gray-900 hover:bg-gray-100'
                    >
                      <span className='sr-only'>View favorites</span>
                      <HeartIcon className='h-6 w-6' aria-hidden='true' />
                    </Link>
                    <Notifications></Notifications>
                    <ProfileMenu></ProfileMenu>
                    {/* Profile dropdown */}
                  </div>
                  <div className='-mr-2 flex items-center justify-center lg:hidden'>
                    {/* Mobile menu button */}

                    <Link
                      to='/your-workouts'
                      className='p-1 rounded-full mr-1 text-gray-900 hover:bg-gray-100'
                    >
                      <span className='sr-only'>View favorites</span>
                      <HeartIcon className='h-6 w-6' aria-hidden='true' />
                    </Link>
                    <div className='mr-3'>
                      <Notifications></Notifications>
                    </div>
                    <ProfileMenu></ProfileMenu>
                  </div>
                </div>
              </div>
              {/* Mobile menu items */}
              <Disclosure.Panel className='lg:hidden'>
                <div className='pt-2 pb-3 pl-2.5 pr-2 space-y-1'>
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  {NAVIGATIONS?.map(({ url, label }) => (
                    <Disclosure.Button
                      key={`mobile_nav_${url}-${label}`}
                      as={Link}
                      to={url}
                      className={`block text-tiny rounded-md py-2 px-3 ${
                        location.pathname === url
                          ? 'bg-gray-100 text-gray-900 font-semibold'
                          : 'text-gray-500 hover:bg-gray-50 font-medium'
                      }`}
                    >
                      {label}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className='pt-4 pb-3 border-t border-gray-200'>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      {user.profileImageUrl &&
                        user.profileImageUrl !== null && (
                          <img
                            className='h-10 w-10 rounded-full object-cover object-center'
                            src={`${user?.profileImageUrl}`}
                            alt=''
                          />
                        )}
                      {(user.profileImageUrl === null ||
                        user.profileImageUrl === undefined) && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0.5 0.5 47 47'
                          height='100%'
                          className='h-10 w-10 rounded-full'
                        >
                          <circle
                            cx='24'
                            cy='24'
                            r='22.5'
                            fill='#fffefc'
                            stroke='#CBCBCB'
                            strokeWidth='2'
                          ></circle>
                          <circle
                            cx='24'
                            cy='18.3'
                            r='7.7'
                            fill='none'
                            stroke='#CBCBCB'
                            strokeWidth='2'
                          ></circle>
                          <path
                            fill='none'
                            stroke='#CBCBCB'
                            strokeWidth='2'
                            d='M8.5 40.1c0-7.3 4.7-13.3 11.3-15.2m8.3 0c6.7 1.8 11.5 8 11.5 15.2'
                          ></path>
                        </svg>
                      )}
                      {/* <img
                      className='h-10 w-10 rounded-full object-cover object-center'
                      src={`${user?.profileImageUrl}`}
                      alt=''
                    /> */}
                    </div>
                    <div className='ml-3'>
                      <div className='font-medium text-gray-800'>
                        {user?.firstName} {user?.lastName}
                      </div>
                      <div className='text-sm font-medium text-gray-500'>
                        {user?.email}
                      </div>
                    </div>
                    <Link
                      to='/news'
                      onClick={() => {
                        markNotifcationAsRead(
                          news[0].published_at,
                          setData,
                          data
                        )
                      }}
                      className='ml-auto flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500'
                    >
                      <Disclosure.Button>
                        <span className='sr-only'>View notifications</span>
                        <BellIcon className='h-6 w-6' aria-hidden='true' />
                        {newMessage && (
                          <svg
                            className='absolute ml-5 h-2 -mt-6 w-2 text-red-400'
                            fill='currentColor'
                            viewBox='0 0 8 8'
                          >
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                        )}
                      </Disclosure.Button>
                    </Link>
                  </div>
                  <div className='mt-3 space-y-1 pl-1 pr-1 text-tiny'>
                    <Disclosure.Button
                      as='a'
                      href='https://members.withsara.co/member/sign_in'
                      className='block px-4 py-2 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                    >
                      Account settings
                    </Disclosure.Button>
                    <Disclosure.Button
                      as={Link}
                      to='/upgrade'
                      className='block px-4 py-2 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                    >
                      Upgrade to annual
                    </Disclosure.Button>
                    <Disclosure.Button
                      as={Link}
                      to='/share'
                      className='block px-4 py-2 font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100'
                    >
                      Invite a friend
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </div>
  )
}
