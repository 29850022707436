const BASE_URL = process.env.REACT_APP_API_URL

// export const updateUser = async (data, id) => {
//   try {
//     id = 6
//     data = {
//       data: {
//         name: 'test',
//         favorite: { 1: true, 2: true },
//         done: { 3: true, 2: true },
//         playlist: [
//           { name: 'First', workouts: { 1: true, 2: true } },
//           { name: 'Second', workouts: { 1: true, 2: true } },
//         ],
//       },
//     }
//     const res = await fetch(`${BASE_URL}/users/${id}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + localStorage.getItem('token'),
//       },
//       body: JSON.stringify(data),
//     })
//     if (res.status !== 200) {
//       return false
//     }
//     // const data = await res.json()
//     return true
//   } catch (error) {
//     return false
//   }
// }

/**
 *
 * @param {number} id - workout id
 * @param {boolen} status - true or false
 * @param {function} error - callback function
 * @returns
 */
export const setWorkoutDoneStatus = async (id, source_name, error) => {
  try {
    const workout = id
    const status = true
    const res = await fetch(`${BASE_URL}/meta-user-infos/updateRow`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        workout,
        source_name,
        status,
      }),
    })

    // const res = await fetch(`${BASE_URL}/workouts/${id}/done`, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + localStorage.getItem('token'),
    //   },
    // }
    if (res.status !== 200) {
      error(res.statusText)
      return false
    }
    return true
  } catch (error) {
    error(error)
    return false
  }
}

/**
 *
 * @param {number} id - workout id
 * @param {boolen} status - true or false
 * @param {function} error - callback function
 * @returns
 */
export const setWorkoutFavoriteStatus = async (id, error) => {
  try {
    const res = await fetch(`${BASE_URL}/workouts/${id}/favorite`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    if (res.status !== 200) {
      error(res.statusText)
      return false
    }
    return true
  } catch (error) {
    error(error)
    return false
  }
}

export const setChallengeJoinStatus = async (id, error) => {
  try {
    const res = await fetch(`${BASE_URL}/monthly-challenges/${id}/join`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    if (res.status !== 200) {
      error(res.statusText)
      return false
    }
    return true
  } catch (error) {
    error(error)
    return false
  }
}

export const saveShareInfo = async (text, error) => {
  try {
    const data = { emails: text }
    const res = await fetch(`${BASE_URL}/share-forms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
    if (res.status !== 200) {
      error(res.statusText)
      return false
    }
    return true
  } catch (error) {
    error(error)
    return false
  }
}
