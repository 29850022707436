import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router-dom'
import AppContext from '../context/AppContext'
import rehypeRaw from 'rehype-raw'
const BASE_URL = process.env.REACT_APP_API_URL
const NewsItem = () => {
  const { id } = useParams()
  const { data, setData } = useContext(AppContext)
  const history = useHistory()
  const { news } = data
  const newsItem = news.find((item) => item.id.toString() === id.toString())

  return (
    <div className='container'>
      <div>
        {history.action !== 'POP' && (
          <div
            className='text-gray-500 flex mt-5 space-x-1 cursor-pointer'
            onClick={() => {
              history.goBack()
            }}
          >
            <ChevronLeftIcon className='h-3.5 w-3.5 mt-0.5 sm:h-5 sm:w-5  text-gray-300'></ChevronLeftIcon>
            <div className='text-sm sm:text-base'>Back</div>
          </div>
        )}
      </div>
      <div>
        <div className='containerSuperSmall'>
          <div className='text-center mt-10 mb-3 text-3xl sm:text-4xl font-bold'>
            {newsItem?.name}
          </div>
          <div className='text-gray-900 text-sm sm:text-base font-bold justify-center text-center'>
            {new Date(newsItem?.published_at).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </div>
          <ReactMarkdown
            className='markdownHtml'
            rehypePlugins={[rehypeRaw]}
            transformImageUri={(src, alt, title) => `${BASE_URL}${src}`}
          >
            {newsItem?.content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )
}

export default NewsItem
