import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CheckIcon } from '@heroicons/react/solid'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import { XIcon } from '@heroicons/react/outline'
export const SignUpModal = ({ onClose, closable }) => {
  const [open, setOpen] = useState(true)
  // const [selectedPlanIndex, setselectedPlanIndex] = useState(0)

  const { data, setData } = useContext(AppContext)
  const { selectedPlanIndex } = data
  const { user } = data

  const plans = [
    {
      name: 'Monthly subscription',
      text: '7 day free trial, cancel anytime',
      price: '$39/month',
      href: 'https://members.withsara.co/member/plans/6a1c76a13a',
    },
    {
      name: 'Yearly subscription',
      text: '7 day free trial, cancel anytime',
      text2: 'Then $229 a year',
      price: '$19/month',
      href: 'https://members.withsara.co/member/plans/vfngpo0vuf',
    },
  ]

  return (
    <Transition.Root show={open} as={Fragment} data-ms-hide='paid'>
      <Dialog
        as='div'
        closable={closable}
        className='fixed z-10 inset-0 overflow-y-auto'
        onClose={() => {
          if (closable) {
            setOpen(false)
            onClose()
          }
        }}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                {closable && (
                  <button
                    type='button'
                    className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none'
                    onClick={() => {
                      if (closable) {
                        setOpen(false)
                        onClose()
                      }
                    }}
                  >
                    <span className='sr-only'>Close</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                )}
              </div>
              <div>
                <div className='mt-3 text-center sm:mt-5'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900 px-5'
                  >
                    Choose your plan
                  </Dialog.Title>
                  <div className='mt-2 text-base text-gray-500 px-5'>
                    Start with a 7-day free trial, cancel any time.
                  </div>
                </div>
              </div>
              <div className='flex justify-center mb-5'>
                <div className='flex flex-col justify-center mt-8 sm:max-w-xl '>
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10'>
                    {plans.map((plan, index) => (
                      <div
                        className={`flex flex-col cursor-pointer p-5 border-2 rounded-lg w-56 ${
                          selectedPlanIndex === index
                            ? 'border-gray-900'
                            : 'border-gray-300'
                        }`}
                        onClick={() =>
                          setData({ ...data, ...{ selectedPlanIndex: index } })
                        }
                        // setselectedPlanIndex(index)}
                        key={`sign_up_plan_${index}`}
                      >
                        <div className='flex justify-between relative'>
                          <div className='font-bold text-sm'>{plan.name}</div>
                          <div>
                            {selectedPlanIndex === index && (
                              <div className='absolute bg-gray-900 h-5 w-5 rounded-full  -ml-2 -mt-2'>
                                <CheckIcon className='h-5 w-5 p-1 text-white'></CheckIcon>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='text-gray-400 text-xs font-medium'>
                          {plan.text}
                        </div>
                        {plan.text2 && (
                          <div className='text-gray-400 text-xs font-medium'>
                            {plan.text2}
                          </div>
                        )}
                        <div className='font-medium'>{plan.price}</div>
                      </div>
                    ))}
                  </div>
                  <div className='mt-5 sm:mt-6'>
                    <a
                      className='inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-900 sm:text-sm'
                      onClick={() => {
                        // setOpen(false)
                        // onClose()
                      }}
                      href={plans[selectedPlanIndex].href}
                    >
                      Next
                    </a>
                  </div>
                  {user && !user.email && (
                    <div className='mt-10 justify-center flex text-gray-500 text-sm'>
                      <div>
                        Already a member?{' '}
                        <span>
                          <Link to='/login' className='underline'>
                            Log in
                          </Link>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <SignUpComponent></SignUpComponent> */}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

SignUpModal.defaultProps = {
  onClose: () => {},
  closable: true,
}

SignUpModal.propTypes = {
  onClose: PropTypes.func,
  closable: PropTypes.bool,
}
