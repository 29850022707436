import ReactGA from 'react-ga'

const sendEvent = (category, action, label) => {
  try {
    console.log('GA event:', category, ':', action, ':', label)
    ReactGA.event({
      category,
      action,
      label,
    })
  } catch (error) {
    console.log(error)
  }
}

export default sendEvent
