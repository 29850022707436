import React from 'react'
import { useContext } from 'react'
import MainHeadline from '../components/MainHeadline'
import AppContext from '../context/AppContext'
import NewsCard from '../components/NewsCard'

const News = () => {
  const title = 'News and Notifications'
  const description =
    'Updates on new classes and features, and articles about holistic health'
  const { data } = useContext(AppContext)
  const { news } = data

  return (
    <div className='containerSmall'>
      <MainHeadline title={title} desciption={description}></MainHeadline>
      <div className='flex flex-col justify-center space-y-5 items-center my-20'>
        {news.map((news_item, index) => (
          <div
            key={`news_item_${news_item?.id}-${index}`}
            className='w-full containerSmall'
          >
            <NewsCard
              id={news_item.id}
              name={news_item.name}
              text={news_item.description}
              date={news_item.published_at}
            ></NewsCard>
          </div>
        ))}
      </div>
    </div>
  )
}

export default News
