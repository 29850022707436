import MainHeadline from '../components/MainHeadline'
import { isNoActive } from '../utils/Common'
import { Link } from 'react-router-dom'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import { SignUpComponent } from '../components/SignUpComponent'
const BASE_URL = process.env.REACT_APP_API_URL
const LINK = '/challenges'

const Challenges = () => {
  const { data, setData } = useContext(AppContext)
  let { monthly_challenges } = data

  return (
    <div className='container'>
      <MainHeadline
        title='Programs & challenges'
        desciption='Find a program to achieve your goals or kickstart your routine with a challenge'
      ></MainHeadline>

      {isNoActive(data) && (
        <div>
          <div className='flex justify-center'>
            <SignUpComponent></SignUpComponent>
          </div>
        </div>
      )}

      {!isNoActive(data) && (
        <div className='component align-top mt-9 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-8'>
          {monthly_challenges?.map((monthly_challenge, index) => (
            <div key={`challenges_${monthly_challenge?.id}-${index}`}>
              <Link
                to={`${LINK}/${monthly_challenge?.readable_url}`}
                onClick={() => {
                  monthly_challenges[0].trigger = !monthly_challenges[0].trigger
                  setData({ ...data, ...monthly_challenges })
                }}
              >
                <div className='flex flex-col justify-center text-center space-y-2'>
                  <div className='aspect-w-6 aspect-h-6'>
                    {monthly_challenge &&
                      monthly_challenge.image &&
                      monthly_challenge.image.url && (
                        <img
                          className='w-full h-full object-center object-cover lg:w-full lg:h-full rounded hover:shadow'
                          src={`${BASE_URL}${monthly_challenge.image.url}`}
                          alt=''
                        />
                      )}
                  </div>
                  <div className='text-sm sm:text-base font-bold'>
                    {monthly_challenge?.name}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Challenges
