import { CheckIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import AppContext from '../context/AppContext'
import { useContext } from 'react'

export const SignUpComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { data } = useContext(AppContext)
  const { user } = data
  const plans = [
    {
      name: 'Monthly subscription',
      text: '7 day free trial, cancel anytime',
      price: '$39/month',
      href: 'https://members.withsara.co/member/plans/6a1c76a13a',
    },
    {
      name: 'Yearly subscription',
      text: '7 day free trial, cancel anytime',
      text2: 'Then $229 a year',
      price: '$19/month',
      href: 'https://members.withsara.co/member/plans/vfngpo0vuf',
    },
  ]

  return (
    <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-sm transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
      <div>
        <div className='mt-3 text-center sm:mt-5'>
          <h3 className='text-lg leading-6 font-medium text-gray-900 px-5'>
            Choose your plan
          </h3>
          <div className='mt-2 text-base text-gray-500 px-5'>
            Start with a 7-day free trial, cancel any time.
          </div>
        </div>
      </div>
      <div className='flex justify-center mb-5'>
        <div className='flex flex-col justify-center mt-8 sm:max-w-xl '>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10'>
            {plans.map((plan, index) => (
              <div
                className={`flex flex-col cursor-pointer p-5 border-2 rounded-lg w-56 ${
                  selectedIndex === index
                    ? 'border-gray-900'
                    : 'border-gray-300'
                }`}
                onClick={() => setSelectedIndex(index)}
                key={`sign_up_plan_${index}`}
              >
                <div className='flex justify-between relative'>
                  <div className='font-bold text-sm'>{plan.name}</div>
                  <div>
                    {selectedIndex === index && (
                      <div className='absolute bg-gray-900 h-5 w-5 rounded-full  -ml-2 -mt-2'>
                        <CheckIcon className='h-5 w-5 p-1 text-white'></CheckIcon>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-gray-400 text-xs font-medium'>
                  {plan.text}
                </div>
                {plan.text2 && (
                  <div className='text-gray-400 text-xs font-medium'>
                    {plan.text2}
                  </div>
                )}
                <div className='font-medium'>{plan.price}</div>
              </div>
            ))}
          </div>
          <div className='mt-5 sm:mt-6'>
            <a
              className='inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-900 sm:text-sm'
              href={plans[selectedIndex].href}
            >
              Next
            </a>
          </div>
          {user && !user.email && (
            <div className='mt-10 justify-center flex text-gray-500 text-sm'>
              <div>
                Already a member?{' '}
                <span>
                  <Link to='/login' className='underline'>
                    Log in
                  </Link>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
