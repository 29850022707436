import {
  setChallengeJoinStatus,
  setWorkoutDoneStatus,
  setWorkoutFavoriteStatus,
} from './APIUtils'
import moment from 'moment'
import {
  isWorkoutCompleted,
  getWorkoutHistory,
  getWorkoutFavorites,
} from '../utils/Common'

import sendEvent from '../utils/Ga'

const removeWorkoutFromComplete = (userMetaInfo, workoutId, source_name) =>
  userMetaInfo.filter(
    (workout) =>
      !(workout.workout === workoutId && workout.source_name === source_name)
  )

export const setStateWorkoutDoneStatus = async (
  user,
  workout,
  data,
  setData,
  source_name
) => {
  try {
    // if its undefined it will be converted to true by JS
    const dataOriginal = JSON.parse(JSON.stringify(data))

    const userMetaInfo = data.userMetaInfo

    const isDone = isWorkoutCompleted(workout, data, source_name)

    if (source_name !== 'root') {
      const isRootDone = isWorkoutCompleted(workout, data, 'root')
      if (!isRootDone) {
        if (data.userMetaInfo && data.userMetaInfo.length > 0) {
          data.userMetaInfo.unshift({
            users_permissions_user: user.id,
            workout: workout.id,
            source_name: 'root',
            action_time: moment().format('YYYY-MM-DDTHH:mm:ss'),
          })
        } else {
          data.userMetaInfo = [
            {
              users_permissions_user: user.id,
              workout: workout.id,
              source_name: 'root',
              action_time: moment().format('YYYY-MM-DDTHH:mm:ss'),
            },
          ]
        }
      }
    }

    if (isDone) {
      data.userMetaInfo = removeWorkoutFromComplete(
        userMetaInfo,
        workout.id,
        source_name
      )
    } else {
      if (data.userMetaInfo && data.userMetaInfo.length > 0) {
        data.userMetaInfo.unshift({
          users_permissions_user: user.id,
          workout: workout.id,
          source_name,
          action_time: moment().format('YYYY-MM-DDTHH:mm:ss'),
        })
      } else {
        data.userMetaInfo = [
          {
            users_permissions_user: user.id,
            workout: workout.id,
            source_name,
            action_time: moment().format('YYYY-MM-DDTHH:mm:ss'),
          },
        ]
      }
    }
    try {
      // that is not working because we are using different date format
      data.workout_history = getWorkoutHistory(data.userMetaInfo)
    } catch (error) {
      console.log(error)
    }

    // if (workout && 'done_for_users' in workout) {
    //   if (workout.done_for_users.includes(user.id)) {
    //     // remove
    //     data.workouts.map((item) => {
    //       if (item.id === workout.id) {
    //         item.done_for_users = item.done_for_users.filter(
    //           (userId) => userId !== user.id
    //         )
    //       }
    //       return item
    //     })

    //     // remove from user
    //     data.user.done_workouts = data.user.done_workouts.filter(
    //       (item) => item.workout.id !== workout.id
    //     )

    //     // remove from history(sorted)
    //     data.workout_history = data.workout_history.filter(
    //       (item) => item.workout.id !== workout.id
    //     )
    //   } else {
    //     // add
    //     data.workouts.map((item) => {
    //       if (item.id === workout.id) {
    //         item.done_for_users.push(user.id)
    //       }
    //       return item
    //     })

    //     // add to user
    //     data.user.done_workouts.push({
    //       done_date: moment().format(),
    //       workout,
    //     })
    //     // add to workout history (sorted)
    //     data.workout_history.unshift({
    //       done_date: moment().format(),
    //       workout,
    //     })
    //   }
    // }
    // update in state(UI)
    setData({ ...data })
    // save to server
    setWorkoutDoneStatus(workout.id, source_name, (error) => {
      // show error message
      errorHandler(error)
      // roll back state to original
      setData(dataOriginal)
    })
  } catch (error) {
    console.error(error)
  }
}

export const setStateWorkoutFavoriteStatus = async (
  user,
  workout,
  data,
  setData
) => {
  try {
    sendEvent('workout', 'favorite', '')
    // if its undefined it will be converted to true by JS
    const dataOriginal = JSON.parse(JSON.stringify(data))

    if (workout && 'favorite_for_users' in workout) {
      if (workout.favorite_for_users.includes(user.id)) {
        // remove
        data.workouts.map((item) => {
          if (item.id === workout.id) {
            item.favorite_for_users = item.favorite_for_users.filter(
              (userId) => userId !== user.id
            )
          }
          return item
        })

        // remove from user
        data.user.favorite_workouts = data.user.favorite_workouts.filter(
          (item) => item.workout.id !== workout.id
        )

        data.workout_favorites = getWorkoutFavorites(data.user)

        // // remove from user favorites(sorted)
        // data.workout_favorites = data.workout_favorites.filter(
        //   (item) => item.workout.id !== workout.id
        // )
      } else {
        // add
        data.workouts.map((item) => {
          if (item.id === workout.id) {
            item.favorite_for_users.push(user.id)
          }
          return item
        })

        // add to user
        if (data.user.favorite_workouts.length > 0) {
          data.user.favorite_workouts.push({
            done_date: moment().format(),
            workout,
          })
        } else {
          data.user.favorite_workouts = [
            {
              done_date: moment().format(),
              workout,
            },
          ]
        }
        // // add to workout favorites (sorted)
        // data.workout_favorites.unshift({
        //   done_date: moment().format(),
        //   workout,
        // })
        data.workout_favorites = getWorkoutFavorites(data.user)
      }
    }
    // update in state(UI)
    setData({ ...data })
    // save to server
    setWorkoutFavoriteStatus(workout.id, (error) => {
      // show error message
      errorHandler(error)
      // roll back state to original
      setData(dataOriginal)
    })
  } catch (error) {
    console.error(error)
  }
}

const errorHandler = (error) => {
  // alert(error)
}

export const setStateChallegeJoinStatus = async (
  user,
  challenge,
  data,
  setData
) => {
  try {
    // if its undefined it will be converted to true by JS
    const dataOriginal = JSON.parse(JSON.stringify(data))

    data.monthly_challenges = data.monthly_challenges.map((item) => {
      if (item.id === challenge.id) {
        if (item.join_users.includes(user.id)) {
          // remove
          item.join_users = item.join_users.filter(
            (userId) => userId !== user.id
          )
        } else {
          // add
          item.join_users.push(user.id)
        }
      }
      return item
    })

    // update in state(UI)
    setData({ ...data })
    // save to server
    setChallengeJoinStatus(challenge.id, (error) => {
      // show error message
      errorHandler(error)
      // roll back state to original
      setData(dataOriginal)
    })
  } catch (error) {
    console.error(error)
  }
}
