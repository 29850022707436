const BASE_URL = process.env.REACT_APP_API_URL

export const getDataByEndPoint = async (endPoint, filterString, hasJWT) => {
  try {
    filterString = filterString || ''
    hasJWT = hasJWT || false

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (hasJWT) {
      if (!localStorage.getItem('token')) {
        return {}
      }
      options['headers']['Authorization'] =
        'Bearer ' + localStorage.getItem('token')
    }

    const res = await fetch(`${BASE_URL}/${endPoint}?${filterString}`, options)
    return await res.json()
  } catch (error) {
    console.error(error)
  }
  return false
}
