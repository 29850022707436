import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import Constants from '../utils/Constants'
import { filterData } from '../utils/Common'

export default function Pagination() {
  let { data, setData } = useContext(AppContext)
  const { page, workoutsFiltered } = data

  const totalResult = workoutsFiltered.length
  const pages = Math.ceil(totalResult / Constants.PAGES_PER_PAGE)

  const pagesNames = []
  for (var i = 1; i <= pages; i++) {
    pagesNames.push(i)
  }

  const pageStart = Constants.PAGES_PER_PAGE * (page - 1) + 1
  let pageEnd = pageStart + Constants.PAGES_PER_PAGE - 1
  if (pageEnd > totalResult) {
    pageEnd = totalResult
  }

  const setPage = (page) => {
    if (page <= 0) {
      page = 1
    }
    if (page > pages) {
      page = pages
    }
    data.page = page
    data = filterData(data)
    setData({ ...data })
  }

  return (
    <div>
      {pagesNames && pagesNames.length > 1 && (
        <div className='py-3 flex items-center justify-between mb-5 sm:mb-10 border-t border-gray-200 mt-12'>
          <div className='flex-1 flex justify-between sm:hidden'>
            <a
              onClick={() => setPage(page - 1)}
              className='cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50'
            >
              Previous
            </a>
            <a
              onClick={() => setPage(page + 1)}
              className='cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50'
            >
              Next
            </a>
          </div>
          <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
            <div>
              <p className='text-sm text-gray-500'>
                Showing <span className='font-medium'>{pageStart}</span> to{' '}
                <span className='font-medium'>{pageEnd}</span> of{' '}
                <span className='font-medium'>{totalResult}</span> results
              </p>
            </div>
            <div>
              <nav
                className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
                aria-label='Pagination'
              >
                <a
                  onClick={() => setPage(page - 1)}
                  className='cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                >
                  <span className='sr-only'>Previous</span>
                  <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                </a>
                {pagesNames.map((pageName, index) => (
                  <a
                    key={`pagination_el_${pageName}-${index}`}
                    aria-current='page'
                    onClick={() => setPage(parseInt(pageName))}
                    className={`cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      page.toString() === pageName.toString()
                        ? 'z-10 border-gray-500 text-gray-500'
                        : ''
                    }`}
                  >
                    {pageName}
                  </a>
                ))}
                <a
                  onClick={() => setPage(page + 1)}
                  className='cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                >
                  <span className='sr-only'>Next</span>
                  <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                </a>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
