import Constants from './Constants'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
const RODUCTION = process.env.REACT_APP_PRODUCTION
export const createFilterObject = ({
  _workout_body_parts,
  _workout_equipments,
  _workout_lengths,
  _workout_goals,
  _workout_intensities,
  _workout_levels,
  _workout_types,
  _workout_menstrual_types,
}) => {
  const result = []
  result.push(
    getFilterOptions(_workout_types, Constants.WORKOUT_TYPES, 'Class type')
  )
  result.push(
    getFilterOptions(
      _workout_body_parts,
      Constants.WORKOUT_BODY_PARTS,
      'Body part'
    )
  )
  result.push(
    getFilterOptions(_workout_lengths, Constants.WORKOUT_LENGTHS, 'Length')
  )

  result.push(
    getFilterOptions(
      _workout_equipments,
      Constants.WORKOUT_EQUIPMENTS,
      'Equipment'
    )
  )

  result.push(
    getFilterOptions(
      _workout_intensities,
      Constants.WORKOUT_INTENSITIES,
      'Intensity'
    )
  )

  result.push(
    getFilterOptions(
      _workout_levels,
      Constants.WORKOUT_LEVELS,
      'Pre & post-natal'
    )
  )

  result.push(
    getFilterOptions(
      _workout_menstrual_types,
      Constants.WORKOUT_MENSTRUAL_TYPES,
      'Menstrual cycle'
    )
  )

  result.push(getFilterOptions(_workout_goals, Constants.WORKOUT_GOALS, 'Goal'))

  return result
}

const getFilterOptions = (data, id, name) => {
  const result = { id, name, options: [] }
  data.forEach((element) => {
    result.options.push({
      value: element.id,
      label: element.name,
      checked: false,
    })
  })
  return result
}

// Here we checkin if the workout has the property has the id
const ifWorkoutPropertyHasId = (workout, property, propertyIds) => {
  const searchObject = workout[property]

  // if property is Array
  if (Array.isArray(searchObject)) {
    for (let index = 0; index < searchObject.length; index++) {
      const element = searchObject[index]
      if (propertyIds.includes(element.id)) {
        return true
      }
    }
  }
  // Or just object with 1 entity
  else {
    if (
      searchObject &&
      searchObject.id &&
      propertyIds.includes(searchObject.id)
    ) {
      return true
    }
  }
  return false
}

const isWorkoutNotFiltered = (workout, activeFiltersMap) => {
  let tempResult = false
  for (const [key, valueArr] of Object.entries(activeFiltersMap)) {
    tempResult = ifWorkoutPropertyHasId(workout, key, valueArr)
    if (!tempResult) {
      return false
    }
  }
  return true
}

export const filterData = (data) => {
  const activeFiltersMap = data.activeFiltersMap

  data.workoutsFiltered = getOnlySearchableWorkouts([...data.workouts])

  if (data.activeFilters.length > 0) {
    data.workoutsFiltered = data.workoutsFiltered.filter((workout) =>
      isWorkoutNotFiltered(workout, activeFiltersMap)
    )
  }
  data.workoutsFilteredPaginated = _filterPaginatedData(data)
  return data
}

export const sortData = (data, sortElement) => {
  switch (sortElement) {
    case Constants.SORT_NEWEST:
      data.workoutsFiltered = data.workoutsFiltered.sort((a, b) => {
        return moment(a.published_at) > moment(b.published_at) ? -1 : 1
      })
      break
    case Constants.SORT_OLDEST:
      data.workoutsFiltered = data.workoutsFiltered.sort((a, b) => {
        return moment(a.published_at) < moment(b.published_at) ? -1 : 1
      })
      break
    case Constants.SORT_POPULARITY:
      data.workoutsFiltered = data.workoutsFiltered.sort((a, b) => {
        return a.favorite_for_users.length > b.favorite_for_users.length
          ? -1
          : 1
      })
      break
  }
  data.workoutsFilteredPaginated = _filterPaginatedData(data)
  return data
}

const _filterPaginatedData = (data) =>
  data.workoutsFiltered.slice(
    Constants.PAGES_PER_PAGE * (data.page - 1), // 0
    Constants.PAGES_PER_PAGE * data.page // 4*1 3 - 4elem
  )

export const sortByDate = (data, param) =>
  data.sort((a, b) => {
    return moment(a[param]) > moment(b[param]) ? -1 : 1
  })

export const isWorkoutCompleted = (workout, { userMetaInfo }, source_name) => {
  if (!workout || !userMetaInfo || !source_name || userMetaInfo.length === 0)
    return false
  for (let index = 0; index < userMetaInfo.length; index++) {
    const element = userMetaInfo[index]
    if (
      element.workout &&
      element.workout.toString() === workout.id.toString() &&
      element.source_name.toString() === source_name
    ) {
      return true
    }
  }
}

export const isWorkoutFavorite = (user, workout) => {
  if (
    user &&
    workout &&
    user.favorite_workouts &&
    user.favorite_workouts.length > 0
  ) {
    for (let index = 0; index < user.favorite_workouts.length; index++) {
      const element = user.favorite_workouts[index]
      if (
        element.workout &&
        element.workout.id &&
        element.workout.id.toString() === workout.id.toString()
      ) {
        return true
      }
    }
  }
  return false
}

export const isJoinedToChallnge = (user, challenge) => {
  if (
    user &&
    challenge &&
    challenge.join_users &&
    challenge.join_users.length > 0
  ) {
    for (let index = 0; index < challenge.join_users.length; index++) {
      const element = challenge.join_users[index]
      if (user.id !== undefined && element.toString() === user.id.toString()) {
        return true
      }
    }
  }
  return false
}

export const getDayIndexMondaySunday = (date) =>
  date.getDay() === 0 ? 6 : date.getDay() - 1

export const clearLocalStorageWithPrefix = (prefix) => {
  const lenght = prefix.length
  var arr = [] // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, lenght) == prefix) {
      arr.push(localStorage.key(i))
    }
  }
  // Iterate over arr and remove the items by key
  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i])
  }
}

export const getWorkoutSourceName = () => {
  const location = useLocation()

  let pathBeforeWorkout = location.pathname
  if (location.pathname.includes('/workout/')) {
    const cutTill = pathBeforeWorkout.indexOf('/workout/')
    pathBeforeWorkout = pathBeforeWorkout.substring(0, cutTill)
  }
  return pathBeforeWorkout ? pathBeforeWorkout : 'root'
}

export const getLastUrlPath = () => useLocation().pathname.split('/').pop()

export const getWorkoutDoneTime = (data, source_name, workout_id) => {
  if (!data || !data.userMetaInfo || data.userMetaInfo.length === 0) return ''
  const result = data.userMetaInfo.find(
    (element) =>
      element.workout &&
      element.workout.toString() === workout_id.toString() &&
      element.workout &&
      element.source_name.toString() === source_name
  )
  return result ? result.action_time : false
}

export const isWeekDayCompleted = (pattern, data) => {
  if (!data || !data.userMetaInfo || data.userMetaInfo.length === 0)
    return false

  for (let index = 0; index < data.userMetaInfo.length; index++) {
    const element = data.userMetaInfo[index]
    if (
      element &&
      element.source_name &&
      element.source_name.toString() === pattern
    ) {
      return true
    }
  }
  return false
}

// const ifWorkoutExists = (workoutId, workouts) => {
//   if (!workouts || workouts.length === 0) return false
//   for (let index = 0; index < workouts.length; index++) {
//     const element = workouts[index]
//     if (element.id.toString() === workoutId.toString()) {
//       return true
//     }
//   }
//   return false
// }

export const getWorkoutHistory = (userMetaInfo) => {
  let result = []
  if (userMetaInfo === undefined) return result
  // {day '', workouts:[]}
  // array comes sorted from API
  try {
    for (let index = 0; index < userMetaInfo.length; index++) {
      const element = userMetaInfo[index]
      if (element.source_name && element.source_name === 'root') {
        try {
          const day = element.action_time.split('T')[0] // 2021-12-08 for example
          const date = element.action_time // 2021-12-08 for example

          const dayIndex = result.findIndex(
            (item) => item.day.toString() === day.toString()
          )
          if (dayIndex >= 0) {
            result[dayIndex].workouts.push(element)
          } else {
            result.push({ day, date, workouts: [element] })
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
  return result
}

export const getWorkoutFavorites = (user) => {
  let result = []
  try {
    let favorite_workouts = user.favorite_workouts // array

    if (favorite_workouts && favorite_workouts.length > 0) {
      favorite_workouts = sortByDate(favorite_workouts, 'done_date')
      // {day '', workouts:[]}
      // array comes sorted from API
      for (let index = 0; index < favorite_workouts.length; index++) {
        const element = favorite_workouts[index]
        const day = element.done_date.split('T')[0] // 2021-12-08 for example
        const date = element.done_date // 2021-12-08 for example

        const dayIndex = result.findIndex(
          (item) => item.day && item.day.toString() === day.toString()
        )
        if (dayIndex >= 0) {
          result[dayIndex].workouts.push(element)
        } else {
          result.push({ day, date, workouts: [element] })
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
  return result
}

export const isPublishedLessThan30Days = (published_at) => {
  if (!published_at) return false
  const published_at_date = new Date(published_at)
  const now = new Date()
  const published_at_date_ms = published_at_date.getTime()
  const now_ms = now.getTime()
  const diff_ms = now_ms - published_at_date_ms
  const diff_days = Math.round(diff_ms / (1000 * 60 * 60 * 24))
  return diff_days < Constants.NEW_WORKOUT_CRITERIA_IN_DAYS
}

export const isMobileNavNeeded = (location, navigation) => {
  if (location.pathname.includes('/upgrade')) return false
  for (let index = 0; index < navigation.length; index++) {
    const element = navigation[index]
    if (element.url === location.pathname) {
      return true
    }
  }
  return false
}
export const isNavNeeded = (location) => {
  if (location.pathname.includes('/login')) return false
  return true
}

export const notPage = (pathArr, loc) => {
  try {
    for (let index = 0; index < pathArr.length; index++) {
      const path = pathArr[index]
      if (loc.pathname === path) {
        return true
      }
    }
    return false
  } catch (error) {
    return true
  }
}

export const markNotifcationAsRead = (published_at, setData, data) => {
  clearLocalStorageWithPrefix('recentNews_')
  localStorage.setItem('recentNews_' + published_at, true)
  setData({ ...data, ...{ newMessage: false } })
}

export const isNoActive = (data) => {
  if (RODUCTION !== 'true') return false
  return data.subsciptionType === 'noActive'
}

export const getOnlySearchableWorkouts = (workouts) => {
  return workouts.filter((workout) => {
    if (workout.is_searchable === null || workout.is_searchable === true) {
      return true
    }
    return false
  })
}
