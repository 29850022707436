import React from 'react'
import { useLocation } from 'react-router-dom'
import AppContext from '../context/AppContext'
import { useContext } from 'react'
import { Fragment, useState } from 'react'
import { useEffect } from 'react'

import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Constants from '../utils/Constants'
import { filterData, sortData } from '../utils/Common'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const getActiveFiltersCount = (sectionId, activeFilters) => {
  let count = 0
  activeFilters.map((filter) => {
    if (filter.section.id === sectionId) {
      count++
    }
  })
  return count
}

const checkboxClick = (section, option, setData, data) => {
  const _activeFilters = []
  data.filters = data.filters.map((filter) => {
    if (filter.id === section.id) {
      filter.options = filter.options.map((opt) => {
        if (opt.value === option.value) {
          opt.checked = !opt.checked
        }
        return opt
      })
    }
    return filter
  })

  data.filters.map((filter) => {
    filter.options.map((opt) => {
      if (opt.checked) {
        _activeFilters.push({
          section: filter,
          option: opt,
          value: opt.value,
          label: opt.label,
        })
      }
    })
  })
  data.activeFilters = _activeFilters
  data.activeFiltersMap = formActiveFiltersMap(data.activeFilters)
  data.page = 1
  data = filterData(data)
  setData({ ...data })
}

const formActiveFiltersMap = (activeFilters) => {
  const result = {}
  activeFilters.map((filter) => {
    if (!result[filter.section.id]) {
      result[filter.section.id] = [filter.value]
    } else {
      result[filter.section.id].push(filter.value)
    }
  })
  return result
}

const resetFilters = (setData, data) => {
  const _activeFilters = []
  data.filters = data.filters.map((filter) => {
    filter.options = filter.options.map((opt) => {
      opt.checked = false
      return opt
    })
    return filter
  })

  data.activeFilters = _activeFilters
  data.activeFiltersMap = formActiveFiltersMap(data.activeFilters)
  data = filterData(data)
  setData({ ...data })
}

const sortBy = (name, setData, data) => {
  let sortElement = Constants.SORT_NEWEST
  data.sortOptions.map((opt) => {
    opt.current = false
    if (opt.name === name) {
      opt.current = true
      sortElement = opt.name
    }
    return opt
  })
  data.page = 1
  sortData(data, sortElement)
  setData({ ...data })
}
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const applyFilter = (filterString, data, setData) => {
  if (filterString && filterString.length > 0 && filterString.includes(':')) {
    const params = filterString.split(':')
    const param = params[0]
    const value = params[1]
    let section = {}
    let option = {}
    data.filters.forEach((filter) => {
      if (filter.id == param) {
        section = filter
        section.options.map((opt) => {
          if (opt.value.toString() === value.toString()) {
            option = opt
          }
          return opt
        })
      }
    })
    checkboxClick(section, option, setData, data)
  }
}

export const Filter = () => {
  let query = useQuery()
  const filterString = query.get('filter')
  const sortString = query.get('sortBy')

  const { data, setData } = useContext(AppContext)
  const { filters, activeFilters, sortOptions } = data

  useEffect(() => {
    try {
      resetFilters(setData, data)
      if (sortString && sortString.length > 0) {
        sortBy(sortString, setData, data)
      }
      applyFilter(filterString, data, setData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const [open, setOpen] = useState(false)

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 sm:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-900 bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto'>
              <div className='px-4 flex items-center justify-between'>
                <h2 className='text-lg font-medium text-gray-900'>Filters</h2>
                <button
                  className='-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>

              {/* Filters */}
              <form className='mt-4'>
                {filters?.map((section, index) => (
                  <Disclosure
                    as='div'
                    key={`${section.name}-mobile-${index}`}
                    className='border-t border-gray-200 px-4 py-6'
                  >
                    {({ open }) => (
                      <>
                        <h3 className='-mx-2 -my-3 flow-root'>
                          <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-500'>
                            <span className='font-medium text-gray-900'>
                              {section.name}
                            </span>
                            <span className='ml-6 flex items-center'>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? '-rotate-180' : 'rotate-0',
                                  'h-5 w-5 transform'
                                )}
                                aria-hidden='true'
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className='pt-6'>
                          <div className='space-y-6'>
                            {section?.options?.map((option, optionIdx) => (
                              <div
                                key={`${section.name}-option-mobile-${option.value}-${optionIdx}`}
                                className='flex items-center'
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  defaultChecked={option.checked}
                                  type='checkbox'
                                  onClick={() => {
                                    checkboxClick(
                                      section,
                                      option,
                                      setData,
                                      data
                                    )
                                  }}
                                  className='h-4 w-4 border-gray-500 rounded text-gray-900 focus:ring-2 cursor-pointer focus:ring-gray-900'
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className='ml-3 text-sm text-gray-900'
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters */}
      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          Filters
        </h2>
        <div className='container'>
          <div className='relative z-10 pb-4'>
            <div className='mx-auto flex items-center justify-between'>
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 hover:font-extrabold'>
                    Sort
                    <ChevronDownIcon
                      className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-500 group-hover:text-gray-700'
                      aria-hidden='true'
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='py-1'>
                      {sortOptions?.map((option, index) => (
                        <Menu.Item key={`option-sort-${option.name}-${index}`}>
                          {({ active }) => (
                            <span
                              onClick={() => {
                                sortBy(option.name, setData, data)
                              }}
                              className={classNames(
                                option.current
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm cursor-pointer'
                              )}
                            >
                              {option.name}
                            </span>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button
                className='inline-block text-sm font-semibold sm:font-medium text-gray-900 sm:text-gray-500 hover:text-gray-900 sm:hidden'
                onClick={() => setOpen(true)}
              >
                Filters
              </button>

              <div className='hidden sm:block'>
                <div className='flow-root'>
                  <Popover.Group className='-mx-4 flex items-center divide-x divide-gray-200'>
                    {filters?.map((section, index) => (
                      <Popover
                        key={`${section.name}-desctop-${index}`}
                        className='px-4 relative inline-block text-left'
                      >
                        <Popover.Button className='group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
                          <span>{section.name}</span>
                          {getActiveFiltersCount(section.id, activeFilters) >
                          0 ? (
                            <span className='ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-500 tabular-nums'>
                              {getActiveFiltersCount(section.id, activeFilters)}
                            </span>
                          ) : null}
                          <ChevronDownIcon
                            className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-500 group-hover:text-gray-700'
                            aria-hidden='true'
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <form className='space-y-4'>
                              {section?.options?.map((option, optionIdx) => (
                                <div
                                  key={`${section.name}-desctop-option-${option.value}-${optionIdx}`}
                                  className='flex items-center'
                                >
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type='checkbox'
                                    onClick={() => {
                                      checkboxClick(
                                        section,
                                        option,
                                        setData,
                                        data
                                      )
                                    }}
                                    defaultChecked={option.checked}
                                    className='h-4 w-4 border-gray-500 rounded text-gray-900 focus:ring-2 focus:ring-gray-900 cursor-pointer'
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className='ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </form>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ))}
                  </Popover.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        {activeFilters && activeFilters.length !== 0 && (
          <div className='w-full border-t border-b border-gray-200 mt-3 inset-x-0 bg-gray-100 mb-9'>
            <div className='container'>
              <div className='mx-auto py-3 sm:flex sm:items-center'>
                <div className='text-xs font-semibold uppercase tracking-wide text-gray-500'>
                  Filters
                  <span className='sr-only'>, active</span>
                </div>

                <div
                  aria-hidden='true'
                  className='hidden w-px h-5 bg-gray-300 sm:block sm:ml-4'
                />

                <div className='mt-2 sm:mt-0 sm:ml-4'>
                  <div className='-m-1 flex flex-wrap items-center'>
                    {activeFilters?.map((activeFilter, index) => (
                      <span
                        key={`active_filter_${activeFilter.value}_${activeFilter.label}-${index}`}
                        className='m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-medium bg-white text-gray-900'
                      >
                        <span>{activeFilter.label}</span>
                        <button
                          onClick={() => {
                            checkboxClick(
                              activeFilter.section,
                              activeFilter.option,
                              setData,
                              data
                            )
                          }}
                          className='flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-500 hover:bg-gray-200 hover:text-gray-500'
                        >
                          <span className='sr-only'>
                            Remove filter for {activeFilter.label}
                          </span>
                          <svg
                            className='h-2 w-2'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 8 8'
                          >
                            <path
                              strokeLinecap='round'
                              strokeWidth='1.5'
                              d='M1 1l6 6m0-6L1 7'
                            />
                          </svg>
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  )
}
